define("a24-staffshift-subdomain-agency/routes/index/agency-candidate-search", ["exports", "a24-ember-staffshift-core/routes/abstractRoute"], function (exports, _abstractRoute) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _abstractRoute.default.extend({
        //This will make that these query params replace history instead of making new history items in the
        //browser when they change.
        queryParams: {
            city: {
                replace: true
            },
            physical_address_geo_location: {
                replace: true
            }
        },
        _bResetQueryParamReplace: false,

        /**
         * This function will be called after the abstract model code before it resolves, thus devs can use this
         * function the same way that they would have used the model function
         *
         * @returns Null or a promise object, in case of promise abstract model will wait till promise is resolved
         *     before resolving its own promise
         */
        modelHook: function modelHook() {},
        actions: {
            queryParamsDidChange: function queryParamsDidChange(objChangedQueries, objAllQueries /*, objRemovedQueries*/) {
                if (objAllQueries.pos_shift_order_id) {
                    //The queryParamsDidChange fires before the actual items appear in the url of the browser, hence the
                    //need for the _bResetQueryParamReplace to reset the replace before the next query params gets applied
                    if (this.get("_bResetQueryParamReplace")) {
                        //Reset the 2 query params to start adding history to browser when they change
                        this.set("queryParams.city.replace", false);
                        this.set("queryParams.physical_address_geo_location.replace", false);
                    } else if (objChangedQueries.physical_address_geo_location || objChangedQueries.city) {
                        this.set("_bResetQueryParamReplace", true);
                    }
                } else {
                    //Reset the 2 query params to start adding history to browser when they change
                    this.set("queryParams.city.replace", false);
                    this.set("queryParams.physical_address_geo_location.replace", false);
                }
            }
        }
    });
});