define("a24-staffshift-subdomain-agency/components/page-agency-list-shifts", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _environment, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        objQueryParams: null,
        bDatagridLoading: false,

        _iProcessedCount: 0,

        _bShowCancelPopup: false,
        _bShowCancelConfirmPopup: false,
        _bCancelPopupLoading: false,

        _objGetSiteSuggestions: null,
        _objGetOrgSuggestions: null,
        _objGetJobTitleSuggestions: null,
        _objDatagridConfig: null,
        _objSelectedItem: null,
        _objEntityActionShiftPromise: null,
        _objShiftStatusReasonsPromise: null,
        _objShiftStatusChangePromise: null,

        _arrSelectedItems: null,
        _arrStatusReasons: null,
        _arrEntityActions: null,
        _arrFailedCancelShifts: null,

        _objStringsService: Ember.inject.service("a24-strings"),
        _objSSCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),
        _objSSAgencyStrings: Ember.inject.service("a24-strings-staffshift-agency"),
        _objStringsCandidateProfile: Ember.inject.service("a24-strings-candidate-profile"),
        _objSSCoreEnum: Ember.inject.service("a24-enums-staffshift-core"),

        _objUserSession: Ember.inject.service("user-session"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigation: Ember.inject.service("navigation"),
        _objSnackbarService: Ember.inject.service("snackbar"),

        _objAgencySiteService: Ember.inject.service("rest-agency-site"),
        _objAgencyOrgService: Ember.inject.service("rest-agency-organisation"),
        _objRestAgencyJobTitle: Ember.inject.service("rest-agency-job-title"),
        _objRestEntityActionShift: Ember.inject.service("rest-entity-action-shift"),
        _objRestShiftStatusReasons: Ember.inject.service("rest-shift-status-reasons"),
        _objRestShiftStatusChange: Ember.inject.service("rest-shift-status-change"),
        _objAgencySavedSearchService: Ember.inject.service("rest-agency-saved-searches"),

        _objAgencyUsersService: Ember.inject.service("rest-agency-users"),
        _objAgencyUsersServicePromise: null,

        _bPageLoading: true,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.get("_objTitleBarService").setTitleBar(this.get("_objSSAgencyStrings").getString("searchShifts"));

            this.set("_objCancelSpecialPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._popupCancelClose();
                }
            });

            // set the end from date to now here by default
            var sDefaultEndDate = this._getDefaultEndDateFilter();
            if (a24Core.isEmpty(this.get("objQueryParams.end_date.sValue"))) {
                this.set("objQueryParams.end_dateFrom.sValue", sDefaultEndDate);
            }

            // Build up the url with context id.
            this.set("_sUrlBase", _environment.default.sRestApiBaseUrl + "/v1/agency/" + this.get("_objNavigation.sContextId") + "/advanced-search/agency-shift");

            this.set("objChildConfig", {
                "objChildren.sReason": function objChildrenSReason(objMixin) {
                    a24Validation.addRequired(objMixin, true);
                }
            });

            this._getStatusReasonsForCancel();
        },

        _getDefaultEndDateFilter: function _getDefaultEndDateFilter() {
            var objNowDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

            return objNowDate.getDateTimeFormat();
        },

        _getStatusReasonsForCancel: function _getStatusReasonsForCancel() {
            var _this2 = this;

            if (a24Core.isEmpty(this.get("_arrStatusReasons"))) {
                this.set("_bPageLoading", true);
                this.get("_objRestShiftStatusReasons").getShiftsStatusReasons(this, "_objShiftStatusReasonsPromise", this.get("_objSSAgencyStrings").getString("cancelled"), function (arrResponse) {
                    _this2._setStatusReasons(arrResponse);
                    _this2._setupPageConfig();
                    _this2._setDatagridActions();
                    _this2.set("_bPageLoading", false);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                    _this2.set("_arrStatusReasons", []);
                    _this2._setupPageConfig();
                    _this2._setDatagridActions();
                    _this2.set("_bPageLoading", false);
                }));
            }
        },

        _setStatusReasons: function _setStatusReasons(arrStatusReasons) {
            // Map the dropdown list with status reasons
            var arrStatusReasonsDropdown = [];
            if (!a24Core.isEmpty(arrStatusReasons)) {
                for (var i = 0; i < arrStatusReasons.length; i++) {
                    var objStatusReasonDropDownItem = {
                        sValue: arrStatusReasons[i],
                        sLabel: arrStatusReasons[i]
                    };
                    arrStatusReasonsDropdown.push(objStatusReasonDropDownItem);
                }
            }
            this.set("_arrStatusReasons", arrStatusReasonsDropdown);
        },

        _setupPageConfig: function _setupPageConfig() {
            var _this3 = this;

            var arrAssignmentStatusOption = this.get("_objSSCoreEnum").getList("assignmentStatus");
            var arrOrderStatusDropdown = [];
            for (var k = 0; k < arrAssignmentStatusOption.length; k++) {
                arrOrderStatusDropdown.push({
                    sTitle: arrAssignmentStatusOption[k].sLabel,
                    sValue: arrAssignmentStatusOption[k].sLabel // This is done to comply with api
                });
            }

            // This array contain the datagrid columns
            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objSSAgencyStrings").getString("orderReference"),
                sProperty: "order_reference",
                sFilterType: "text",
                bText: true,
                bIsClickable: true,
                iWidth: 5
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objStringsCandidateProfile").getString("startDate"),
                sProperty: "start_date",
                sFilterType: "date",
                bFilterAllowTime: true,
                iWidth: 15,
                bCustom: true,
                sTableCustomComponent: "card-datagrid-table-cell-custom-start-date"
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objStringsCandidateProfile").getString("endDate"),
                sProperty: "end_date",
                sFilterType: "date",
                bFilterAllowTime: true,
                bDate: true,
                iWidth: 15
            }, {
                iUniqueColNum: 4,
                sTitle: this.get("_objSSAgencyStrings").getString("organisationName"),
                sProperty: "organisation_id",
                sFilterType: "suggest",
                bCustom: true,
                iWidth: 15,
                bFilterOr: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "sOrganisationId",
                sFilterOptionsLabelKey: "sOrganisationName",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: true,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    if (!a24Core.isEmpty(objRowData.organisation_name)) {
                        return Ember.String.htmlSafe("<span title=\"" + objRowData.organisation_name + "\">" + objRowData.organisation_name + "</span>");
                    }
                    return "";
                },
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var objQuery = {
                        sOrganisationName: {
                            mValue: sSuggestVal,
                            bLike: true,
                            bEncode: true
                        },
                        sortBy: {
                            mValue: "sOrganisationName"
                        },
                        items_per_page: {
                            mValue: 10
                        }
                    };

                    _this3.get("_objAgencyOrgService").getAgencyOrganisations(_this3, "_objGetOrgSuggestions", objQuery, function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrData);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }));
                }
            }, {
                iUniqueColNum: 5,
                sTitle: this.get("_objSSAgencyStrings").getString("siteName"),
                sProperty: "site_id",
                sFilterType: "suggest",
                bCustom: true,
                iWidth: 15,
                bFilterOr: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "sSiteId",
                sFilterOptionsLabelKey: "sOrgNameSiteName",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: true,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    if (!a24Core.isEmpty(objRowData.site_name)) {
                        return Ember.String.htmlSafe("<span title=\"" + objRowData.site_name + "\">" + objRowData.site_name + "</span>");
                    }
                    return "";
                },
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var objQuery = {
                        sSiteName: {
                            mValue: sSuggestVal,
                            bLike: true,
                            bEncode: true
                        },
                        sortBy: {
                            mValue: "sSiteName"
                        },
                        items_per_page: {
                            mValue: 10
                        }
                    };

                    _this3.get("_objAgencySiteService").getLinkedClients(_this3, "_objGetSiteSuggestions", objQuery, function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        } else {
                            for (var i = 0; i < arrData.length; i++) {
                                arrData[i].sOrgNameSiteName = arrData[i].sOrganisationName + ": " + arrData[i].sSiteName;
                            }
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrData);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }));
                }
            }, {
                iUniqueColNum: 6,
                sTitle: this.get("_objSSAgencyStrings").getString("wardName"),
                sProperty: "ward_name",
                sFilterType: "text",
                bText: true,
                bFilterOr: true,
                iWidth: 15
            }, {
                iUniqueColNum: 7,
                sTitle: this.get("_objStringsCandidateProfile").getString("jobFamily"),
                sProperty: "job_family_id",
                sFilterType: "suggest",
                bCustom: true,
                bStopColumnSort: true,
                bStopFiltering: true,
                iWidth: 15,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    if (!a24Core.isEmpty(objRowData.job_family_name)) {
                        return Ember.String.htmlSafe("<span title=\"" + objRowData.job_family_name + "\">" + objRowData.job_family_name + "</span>");
                    }
                    return "";
                }
            }, {
                iUniqueColNum: 8,
                sTitle: this.get("_objStringsCandidateProfile").getString("jobTitle"),
                sProperty: "job_title_id",
                sFilterType: "suggest",
                bCustom: true,
                bStopColumnSort: true,
                bFilterAnd: true,
                bFilterOr: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "id",
                sFilterOptionsLabelKey: "sDisplayName",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: true,
                iWidth: 15,
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sQueryString, funcOnComplete) {
                    var objQuery = {
                        sTitleName: {
                            mValue: sQueryString,
                            bLike: true,
                            bEncode: true
                        },
                        sortBy: {
                            mValue: "sTitleName"
                        },
                        items_per_page: {
                            mValue: 10
                        }
                    };

                    _this3.get("_objRestAgencyJobTitle").getJobTitles(_this3, "_objGetJobTitleSuggestions", objQuery, function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        for (var i = 0; i < arrData.length; i++) {
                            arrData[i].sDisplayName = arrData[i].sJobFamilyName + ": " + arrData[i].sTitleName;
                        }

                        // Call the callback with the formatted data
                        funcOnComplete(arrData);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcOnComplete([]);
                    }));
                },
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    if (!a24Core.isEmpty(objRowData.job_title_name)) {
                        return Ember.String.htmlSafe("<span title=\"" + objRowData.job_title_name + "\">" + objRowData.job_title_name + "</span>");
                    }
                    return "";
                }
            }, {
                iUniqueColNum: 9,
                sTitle: this.get("_objStringsService").getString("status"),
                sProperty: "status_code",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: arrOrderStatusDropdown,
                iWidth: 5
            }, {
                iUniqueColNum: 10,
                sTitle: this.get("_objStringsService").getString("county"),
                sProperty: "county",
                sFilterType: "text",
                bText: true,
                iWidth: 10
            }, {
                iUniqueColNum: 11,
                sTitle: this.get("_objSSAgencyStrings").getString("city"),
                sProperty: "city",
                sFilterType: "text",
                bText: true,
                iWidth: 10
            }, {
                iUniqueColNum: 12,
                sTitle: this.get("_objSSAgencyStrings").getString("postCode"),
                sProperty: "post_code",
                sFilterType: "text",
                bText: true,
                iWidth: 10
            }, {
                iUniqueColNum: 13,
                sTitle: this.get("_objSSCoreStrings").getString("candidate"),
                sProperty: "candidate_full_name",
                sFilterType: "text",
                bText: true,
                iWidth: 20
            }, {
                iUniqueColNum: 14,
                sTitle: this.get("_objStringsService").getString("dateCreated"),
                sProperty: "date_created",
                sFilterType: "date",
                bFilterAllowTime: true,
                bDate: true,
                iWidth: 15
            }, {
                iUniqueColNum: 15,
                sTitle: this.get("_objSSAgencyStrings").getString("payrollNumber"),
                sProperty: "payroll_number",
                sFilterType: "text",
                bText: true,
                iWidth: 10
            }, {
                iUniqueColNum: 16,
                sTitle: this.get("_objSSAgencyStrings").getString("shiftReferenceNumber"),
                sProperty: "shift_reference_number",
                sFilterType: "text",
                bText: true,
                iWidth: 10
            }, {
                iUniqueColNum: 17,
                sTitle: this.get("_objSSAgencyStrings").getString("poNumber"),
                sProperty: "po_number",
                sFilterType: "text",
                bText: true,
                iWidth: 10
            }, {
                iUniqueColNum: 18,
                sTitle: this.get("_objSSAgencyStrings").getString("dateFilled"),
                sProperty: "booked_assignment_created_date",
                sFilterType: "date",
                bFilterAllowTime: true,
                iMinWidth: 180,
                bDate: true,
                iWidth: 15
            }, {
                iUniqueColNum: 19,
                sTitle: this.get("_objSSAgencyStrings").getString("filledBy"),
                sProperty: "booked_assignment_created_user_fullname",
                sFilterType: "text",
                bText: true,
                bStopFiltering: true,
                iWidth: 10
            }, {
                iUniqueColNum: 20,
                sTitle: this.get("_objSSAgencyStrings").getString("filledBy"),
                sProperty: "booked_assignment_created_user_id",
                sFilterType: "suggest",
                iWidth: 10,
                bSilent: true,
                bFilterOr: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "id",
                sFilterOptionsLabelKey: "sNameUserName",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: false,
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var objQuery = {
                        items_per_page: {
                            mValue: 10
                        },
                        q: {
                            mValue: sSuggestVal,
                            bLike: true,
                            bEncode: true
                        }
                    };

                    _this3.get("_objAgencyUsersService").getAvailableUserByQuery(_this3, "_objAgencyUsersServicePromise", _this3.get("_objNavigation.sContextId"), "", function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        } else {
                            for (var i = 0; i < arrData.length; i++) {
                                arrData[i].sNameUserName = arrData[i].sFirstName + " " + arrData[i].sLastName + ": " + arrData[i].sUsername;
                            }
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrData);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }), objQuery);
                }
            }, {
                iUniqueColNum: 21,
                sTitle: this.get("_objSSAgencyStrings").getString("filledByUsername"),
                sProperty: "booked_assignment_created_user_username",
                bStopFiltering: true,
                sFilterType: "text",
                bText: true,
                iWidth: 10
            }];

            this.set("_objDatagridConfig", {
                sDatagridHeader: this.get("_objSSAgencyStrings").getString("searchShifts"),
                iMaxHeight: 62 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bEmitDidDoServiceCall: true,
                bHasReloadButton: true,
                arrSingleItemSelectActions: [],
                arrMultiItemSelectActions: [],
                objSavedSearchService: this.get("_objAgencySavedSearchService"),
                sSavedSearchRef: "agency-search-shifts",
                arrHeaderConfig: arrDataGridHeaderConfig
            });
            this.set("_bPageLoading", false);
        },

        _setDatagridActions: function _setDatagridActions() {
            var objThis = this;
            var arrEntityActions = this.get("_arrEntityActions");

            var arrSingleItemActions = [];
            var arrMultiItemActions = [];
            if (!a24Core.isEmpty(arrEntityActions)) {
                arrSingleItemActions = [{
                    sTitle: this.get("_objSSAgencyStrings").getString("viewOrder"),
                    sIcon: "open_in_new",
                    executeCardAction: function executeCardAction() {
                        objThis._viewOrderInNewWindow(objThis.get("_arrSelectedItems"));
                    }
                }, {
                    sTitle: this.get("_objSSAgencyStrings").getString("cancelShift"),
                    sIcon: "assignment",
                    executeCardAction: function executeCardAction() {
                        objThis._cancelShifts(objThis.get("_arrSelectedItems"));
                    },
                    funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                        var bShow = false;
                        var _iteratorNormalCompletion = true;
                        var _didIteratorError = false;
                        var _iteratorError = undefined;

                        try {
                            for (var _iterator = arrSelectedItems[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                                var objItem = _step.value;

                                bShow = arrEntityActions.find(function (objEntityAction) {
                                    if (objEntityAction.entity_id === objItem.shift_id && objEntityAction.actions[0].allowed) {
                                        return true;
                                    }
                                    return false;
                                });
                                if (!bShow) {
                                    break;
                                }
                            }
                        } catch (err) {
                            _didIteratorError = true;
                            _iteratorError = err;
                        } finally {
                            try {
                                if (!_iteratorNormalCompletion && _iterator.return) {
                                    _iterator.return();
                                }
                            } finally {
                                if (_didIteratorError) {
                                    throw _iteratorError;
                                }
                            }
                        }

                        return bShow;
                    }
                }];

                arrMultiItemActions = [{
                    sTitle: this.get("_objSSAgencyStrings").getString("viewOrder"),
                    sIcon: "open_in_new",
                    executeCardAction: function executeCardAction() {
                        objThis._viewOrderInNewWindow(objThis.get("_arrSelectedItems"));
                    }
                }, {
                    sTitle: this.get("_objSSAgencyStrings").getString("cancelShift"),
                    sIcon: "assignment",
                    executeCardAction: function executeCardAction() {
                        objThis._cancelShifts(objThis.get("_arrSelectedItems"));
                    },
                    funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                        var bShow = false;
                        var _iteratorNormalCompletion2 = true;
                        var _didIteratorError2 = false;
                        var _iteratorError2 = undefined;

                        try {
                            for (var _iterator2 = arrSelectedItems[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                                var objItem = _step2.value;

                                bShow = arrEntityActions.find(function (objSelectedItem) {
                                    if (objSelectedItem.entity_id === objItem.shift_id && objSelectedItem.actions[0].allowed) {
                                        return true;
                                    }
                                    return false;
                                });
                                if (!bShow) {
                                    break;
                                }
                            }
                        } catch (err) {
                            _didIteratorError2 = true;
                            _iteratorError2 = err;
                        } finally {
                            try {
                                if (!_iteratorNormalCompletion2 && _iterator2.return) {
                                    _iterator2.return();
                                }
                            } finally {
                                if (_didIteratorError2) {
                                    throw _iteratorError2;
                                }
                            }
                        }

                        return bShow;
                    }
                }];
            } else {
                arrSingleItemActions = [{
                    sTitle: this.get("_objSSAgencyStrings").getString("viewOrder"),
                    sIcon: "open_in_new",
                    executeCardAction: function executeCardAction() {
                        objThis._viewOrderInNewWindow(objThis.get("_arrSelectedItems"));
                    }
                }];
                arrMultiItemActions = [{
                    sTitle: this.get("_objSSAgencyStrings").getString("viewOrder"),
                    sIcon: "open_in_new",
                    executeCardAction: function executeCardAction() {
                        objThis._viewOrderInNewWindow(objThis.get("_arrSelectedItems"));
                    }
                }];
            }

            this.set("_arrSingleItemActions", arrSingleItemActions);
            this.set("_arrMultiItemActions", arrMultiItemActions);

            this.set("_objDatagridConfig.arrSingleItemSelectActions", arrSingleItemActions);
            this.set("_objDatagridConfig.arrMultiItemSelectActions", arrMultiItemActions);
        },

        _cancelShifts: function _cancelShifts() {
            this.set("_bShowCancelPopup", true);
            this.clearAllErrorMessages();
            this.clearForm();
        },

        _popupCancelClose: function _popupCancelClose() {
            this.set("_bShowCancelPopup", false);
            this.set("_bCancelPopupLoading", false);
        },

        _popupCancelConfirm: function _popupCancelConfirm() {
            this.set("_bCancelPopupLoading", true);
            this.set("_bShowCancelConfirmPopup", false);

            var objData = a24Core.removeNullsFromValue(Ember, this.getPlainFormValueObject());
            var arrSelectedShifts = this.get("_arrSelectedItems");
            var objPayload = {
                "sAgencyId": this.get("_objNavigation.sContextId"),
                "sStatusCode": "Cancelled",
                "sStatusReason": objData.sReason
            };

            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objSSAgencyStrings").getString("cancelingShifts"));

            this.set("_arrFailedCancelShifts", []);

            for (var i = 0; i < arrSelectedShifts.length; i++) {
                this._cancelShift(arrSelectedShifts[i], objPayload);
            }
        },

        _cancelShift: function _cancelShift(objShift, objPayload) {
            var objThis = this;
            var funcSuccess = function funcSuccess() {
                objThis.set("_iProcessedCount", objThis.get("_iProcessedCount") + 1);
                objThis._handleResponse();
            };
            var funcFailure = function funcFailure(sCode) {
                var arrFailed = objThis.get("_arrFailedCancelShifts");
                objThis.set("_iProcessedCount", objThis.get("_iProcessedCount") + 1);

                var sReason = "";

                if (sCode === "NOT_IN_SET") {
                    sReason = objThis.get("_objSSAgencyStrings").getString("notValidReasonCancelShift");
                } else if (sCode === "ILLEGAL_TO_STATE") {
                    sReason = objThis.get("_objSSAgencyStrings").getString("shiftStatusPreventCancel");
                } else {
                    sReason = objThis.get("_objSSAgencyStrings").getString("somethingWentWrong");
                }

                arrFailed.push({
                    order_reference: objShift.order_reference,
                    reason: sReason
                });

                objThis.set("_arrFailedCancelShifts", arrFailed);

                objThis._handleResponse();
            };

            this.get("_objRestShiftStatusChange").updateShiftStatusChange(this, "_objShiftStatusChangePromise" + objShift.shift_id, objShift.order_id, objShift.shift_id, objPayload, funcSuccess, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], funcFailure));
        },

        _refreshShiftsList: function _refreshShiftsList() {
            var _this4 = this;

            // Put the page in loading
            this.set("_bPageLoading", true);

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    // Take page out of loading
                    _this4.set("_bPageLoading", false);
                });
            });
        },

        _handleResponse: function _handleResponse() {
            if (this.get("_arrSelectedItems").length === this.get("_iProcessedCount")) {
                this.set("_bCancelPopupLoading", false);
                this.set("_bShowCancelPopup", false);
                this.set("_iProcessedCount", 0);
                if (this.get("_arrFailedCancelShifts").length === 0) {
                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objSSAgencyStrings").getString("successCancelShifts"));
                    this._refreshShiftsList();
                } else {
                    this._showFailedPopup();
                }
            }
        },

        _showFailedPopup: function _showFailedPopup() {
            this.set("_bShowFailedPopup", true);
        },

        _doEntityActionCalls: function _doEntityActionCalls(arrShiftIds) {
            var _this5 = this;

            if (a24Core.isEmpty(arrShiftIds)) {
                this._setDatagridActions();
            } else {
                // This next and the loading is here since the action will not show untill the entity action call finishes
                // By doing this will cause the datagrid to stay in a loading state and only take it out once once the
                // entity action call finishes and the datagrid actions is set
                // This is not optimal and the proper way to do this is for us to update the datagrid to allow the parent to justify
                // the datagrid loading state. But due to us being pressed for time we are not doing that now
                // Ticket to update datagrid for parent to justify the loading state:
                // TODO add ticket here for above
                Ember.run.next(function () {
                    _this5.set("bDatagridLoading", true);
                    var objQuery = {
                        shift_ids: { mValue: arrShiftIds.join(",") }
                    };
                    _this5.get("_objRestEntityActionShift").getShiftsEntityActions(_this5, "_objEntityActionShiftPromise", objQuery, function (arrResponse) {
                        _this5.set("_arrEntityActions", arrResponse);
                        _this5._setDatagridActions();
                        _this5.set("bDatagridLoading", false);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                        _this5.set("_arrEntityActions", []);
                        _this5._setDatagridActions();
                        _this5.set("bDatagridLoading", false);
                    }));
                });
            }
        },

        _viewOrderInNewWindow: function _viewOrderInNewWindow(arrSelectedItems) {
            var _this6 = this;

            var arrUniqueSelectedOrders = [];
            $.each(arrSelectedItems, function (iKey, objOrder) {
                if (!arrUniqueSelectedOrders.includes(objOrder.order_id)) {
                    arrUniqueSelectedOrders.push(objOrder.order_id);
                }
            });

            $.each(arrUniqueSelectedOrders, function (iKey, iOrderId) {
                _this6.viewOrderInNewWindow(iOrderId);
            });
        },

        viewOrderInNewWindow: function viewOrderInNewWindow(sOrderId) {
            var sUrl = _environment.default.sTriageBaseUrl + "/views#agency-staffing-order-details;orderId=" + sOrderId + ";cId=" + this.get("_objNavigation").get("sContextId");

            this.openLink(sUrl);
        },

        openLink: function openLink(sLink) {
            window.open(sLink);
        },


        _viewOrder: function _viewOrder(sOrderId) {
            this.get("_objNavigation").navigateToGWTPage("agency-staffing-order-details;orderId=" + sOrderId + ";cId=" + this.get("_objNavigation").get("sContextId"), true);
        },
        // For multi select when we need it later on
        _onItemsSelect: function _onItemsSelect(arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedItem", arrSelectedItems[0]);
            }
            // Set the selected items
            this.set("_arrSelectedItems", arrSelectedItems);
        },
        actions: {
            onFailedPopupCancel: function onFailedPopupCancel() {
                this.set("_bCancelPopupLoading", false);
                this.set("_bShowFailedPopup", false);
                this.set("_bShowCancelConfirmPopup", false);
                this.set("_bShowFailedPopup", false);
                this._refreshShiftsList();
            },
            onCancelPopupClick: function onCancelPopupClick() {
                this.clearAllErrorMessages();

                if (!this.doFullValidation()) {
                    this.set("_bShowCancelConfirmPopup", true);
                }
            },
            onCancelPopupCancel: function onCancelPopupCancel() {
                this.set("_bShowCancelConfirmPopup", false);
            },
            onCancelPopupConfirm: function onCancelPopupConfirm() {
                this._popupCancelConfirm();
            },
            onCancelPopupClose: function onCancelPopupClose() {
                this._popupCancelClose();
            },
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this._onItemsSelect(null);
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableCell: function cardDatagridTableCell(sColumnName, objRowData) {
                if (sColumnName === "order_reference") {
                    this._viewOrder(objRowData.order_id);
                }
            },
            onDidDoServiceCall: function onDidDoServiceCall(arrTableData, jqXHR, objPOSTBody) {
                var arrShiftIds = [];
                for (var i = 0; i < arrTableData.length; i++) {
                    arrShiftIds.push(arrTableData[i].shift_id);
                }
                this._doEntityActionCalls(arrShiftIds);
                if (!a24Core.isEmpty(this.get("onDidDoServiceCall"))) {
                    this.get("onDidDoServiceCall")(arrTableData, jqXHR, objPOSTBody);
                }
            }
        }
    });
});