define("a24-staffshift-subdomain-agency/components/page-agency-compliance-contract-view", ["exports", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        _sContractId: null,
        _bServerError: null,
        _arrHeaderActions: null,
        _arrComplianceContractData: null,
        _objComplianceContractServicePromise: null,
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objStringsCandidateProfile: Ember.inject.service("a24-strings-candidate-profile"),
        _objSSAgencyStrings: Ember.inject.service("a24-strings-staffshift-agency"),
        _objAgencyComplianceContractService: Ember.inject.service("rest-agency-compliance-contract"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objSSAgencyEnumsService: Ember.inject.service("a24-enums-staffshift-agency"),
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            var objComplianceContractViewParams = this.get("_objNavigationService.objRouteParams")["index.agency-compliance-contract-view"];

            if (!a24Core.isEmpty(objComplianceContractViewParams)) {
                this.set("_sContractId", objComplianceContractViewParams.contract_id);
            }

            this.set("_arrHeaderActions", []);

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objStringsCandidateProfile").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this._getComplianceContract();
                }
            }]);

            this.get("_objTitleBarService").setTitleBar(this.get("_objSSAgencyStrings").getString("complianceContract"), function () {
                _this.get("_objNavigationService").navigateBack();
            }, false, "card-actions-component");

            this._getComplianceContract();
        },

        _getComplianceContract: function _getComplianceContract() {
            var _this2 = this;

            this.set("_bLoading", true);
            this.set("_bServerError", false);

            this.get("_objAgencyComplianceContractService").getComplianceContract(this, "_objComplianceContractServicePromise", this.get("_sContractId"), function (objResponse) {
                if (!a24Core.isEmpty(objResponse)) {
                    _this2.set("_arrComplianceContractData", _this2._formatComplianceContract(objResponse));
                    _this2.get("_objTitleBarService").setTitleBar(_this2.get("_objSSAgencyStrings").getString("complianceContract") + " - " + objResponse.name, function () {
                        _this2.get("_objNavigationService").navigateBack();
                    }, false, "card-actions-component");
                } else {
                    _this2.set("_arrComplianceContractData", []);
                }
                _this2.set("_bLoading", false);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                _this2.set("_bServerError", true);
                _this2.set("_bLoading", false);
            }));
        },

        _formatComplianceContract: function _formatComplianceContract(objComplianceContractData) {
            var arrItems = [];

            arrItems.push({
                sLabel: this.get("_objStringsService").getString("name"),
                sValue: objComplianceContractData.name,
                bText: true
            });

            arrItems.push({
                sLabel: this.get("_objStringsService").getString("description"),
                sValue: objComplianceContractData.description,
                bText: true
            });

            arrItems.push({
                sLabel: this.get("_objStringsService").getString("status"),
                sValue: this.get("_objSSAgencyEnumsService").getFrontendValue("complianceContractStatus", objComplianceContractData.status),
                bText: true
            });

            return [{
                arrItems: arrItems
            }];
        }
    });
});