define("a24-staffshift-subdomain-agency/controllers/index/agency-compliance-rules", ["exports", "a24-staffshift-subdomain-agency/mixins/query-param-object-builder"], function (exports, _queryParamObjectBuilder) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, {
        queryParams: ["name", "description", "position_type", "condition", "page", "sortBy", "vis_cols"],
        name: "",
        description: "",
        position_type: "",
        condition: "",
        page: "1",
        sortBy: "name::asc",
        vis_cols: "1,2,4"
    });
});