define("a24-staffshift-subdomain-agency/controllers/index/agency-search-shift", ["exports", "a24-staffshift-subdomain-agency/mixins/query-param-object-builder"], function (exports, _queryParamObjectBuilder) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, {
        queryParams: ["order_reference", "start_date", "start_dateFrom", "start_dateTo", "end_date", "end_dateFrom", "end_dateTo", "organisation_id", "site_id", "ward_name", "job_family_id", "job_title_id", "status_code", "county", "city", "post_code", "candidate_full_name", "date_created", "date_createdFrom", "date_createdTo", "payroll_number", "shift_reference_number", "po_number", "page", "booked_assignment_created_date", "booked_assignment_created_dateFrom", "booked_assignment_created_dateTo", "booked_assignment_created_user_fullname", "booked_assignment_created_user_id", "booked_assignment_created_user_username", "sortBy", "vis_cols"],
        order_reference: "",
        start_date: "",
        start_dateFrom: "",
        start_dateTo: "",
        end_date: "",
        end_dateFrom: "",
        end_dateTo: "",
        organisation_name: "",
        organisation_id: "",
        site_name: "",
        site_id: "",
        ward_name: "",
        job_family_id: "",
        job_title_id: "",
        status_code: "New|Reopened",
        county: "",
        city: "",
        post_code: "",
        candidate_full_name: "",
        date_created: "",
        date_createdFrom: "",
        date_createdTo: "",
        payroll_number: "",
        shift_reference_number: "",
        po_number: "",
        booked_assignment_created_date: "",
        booked_assignment_created_dateFrom: "",
        booked_assignment_created_dateTo: "",
        booked_assignment_created_user_fullname: "",
        booked_assignment_created_user_id: "",
        booked_assignment_created_user_username: "",
        page: "1",
        sortBy: "start_date::asc",
        vis_cols: "1,2,3,5,6,8,10,11,12"
    });
});