define("a24-staffshift-subdomain-agency/services/a24-enums-staffshift-agency", ["exports", "a24-ember-lookup/services/a24-enums-base"], function (exports, _a24EnumsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24EnumsBase.default.extend({
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSSAgencyStringsService: Ember.inject.service("a24-strings-staffshift-agency"),

        init: function init() {
            this._super.apply(this, arguments);

            var objStrings = this.get("_objStringsService");
            var objSSAgencyStringsService = this.get("_objSSAgencyStringsService");

            this.set("hoursDiff", {
                "EQUAL": {
                    "backend": objSSAgencyStringsService.getString("equal"),
                    "frontend": objSSAgencyStringsService.getString("equal")
                },
                "MORE_THAN": {
                    "backend": objSSAgencyStringsService.getString("moreThan"),
                    "frontend": objSSAgencyStringsService.getString("moreThan")
                },
                "LESS_THAN": {
                    "backend": objSSAgencyStringsService.getString("lessThan"),
                    "frontend": objSSAgencyStringsService.getString("lessThan")
                }
            });

            this.set("availabilityOptions", {
                "AVAILABLE": {
                    "backend": "Available",
                    "frontend": objSSAgencyStringsService.getString("available")
                },
                "BOOKED": {
                    "backend": "Booked",
                    "frontend": objSSAgencyStringsService.getString("booked")
                },
                "UNAVAILABLE": {
                    "backend": "Unavailable",
                    "frontend": objSSAgencyStringsService.getString("unavailable")
                },
                "UNKNOWN": {
                    "backend": "Unknown",
                    "frontend": objSSAgencyStringsService.getString("unknown")
                }
            });

            this.set("intervalStatus", {
                "NEW": {
                    "backend": "New",
                    "frontend": objSSAgencyStringsService.getString("new")
                },
                "CONFIRMED": {
                    "backend": "Confirmed",
                    "frontend": objSSAgencyStringsService.getString("confirmed")
                },
                "AUTHORISED": {
                    "backend": "Authorised",
                    "frontend": objSSAgencyStringsService.getString("authorised")
                },
                "APPROVED": {
                    "backend": "Approved",
                    "frontend": objSSAgencyStringsService.getString("approved")
                },
                "CANCELLED": {
                    "backend": "Cancelled",
                    "frontend": objSSAgencyStringsService.getString("cancelled")
                },
                "REJECTED": {
                    "backend": "Rejected",
                    "frontend": objSSAgencyStringsService.getString("rejected")
                },
                "CLOSED": {
                    "backend": "Closed",
                    "frontend": objSSAgencyStringsService.getString("closed")
                }
            });

            this.set("timesheetStatus", {
                "NEW": {
                    "backend": "New",
                    "frontend": objSSAgencyStringsService.getString("new")
                },
                "CONFIRMED": {
                    "backend": "Confirmed",
                    "frontend": objSSAgencyStringsService.getString("confirmed")
                },
                "AUTHORISED": {
                    "backend": "Authorised",
                    "frontend": objSSAgencyStringsService.getString("authorised")
                },
                "OPEN": {
                    "backend": "Open",
                    "frontend": objSSAgencyStringsService.getString("open")
                },
                "APPROVED": {
                    "backend": "Approved",
                    "frontend": objSSAgencyStringsService.getString("approved")
                },
                "CANCELLED": {
                    "backend": "Cancelled",
                    "frontend": objSSAgencyStringsService.getString("cancelled")
                },
                "REJECTED": {
                    "backend": "Rejected",
                    "frontend": objSSAgencyStringsService.getString("rejected")
                },
                "CLOSED": {
                    "backend": "Closed",
                    "frontend": objSSAgencyStringsService.getString("closed")
                }
            });

            this.set("shiftOptions", {
                "EARLY": {
                    "backend": "Early",
                    "frontend": objSSAgencyStringsService.getString("early")
                },
                "LATE": {
                    "backend": "Late",
                    "frontend": objSSAgencyStringsService.getString("late")
                },
                "NIGHT": {
                    "backend": "Night",
                    "frontend": objSSAgencyStringsService.getString("night")
                }
            });

            this.set("availabilityFilterDateOptions", {
                "DATE_FILTER_TODAY": {
                    "backend": "dateFilterToday",
                    "frontend": objStrings.getString("dateFilterToday")
                },
                "DATE_FILTER_TOMORROW": {
                    "backend": "dateFilterTomorrow",
                    "frontend": objSSAgencyStringsService.getString("dateFilterTomorrow")
                },
                "DATE_FILTER_NEXT_5_DAYS": {
                    "backend": "dateFilterNext5Days",
                    "frontend": objSSAgencyStringsService.getString("dateFilterNext5Days")
                },
                "DATE_FILTER_SPECIFIC_DATE": {
                    "backend": "dateFilterSpecificDate",
                    "frontend": objSSAgencyStringsService.getString("dateFilterSpecificDate")
                },
                "DATE_FILTER_ALL_DATES_IN_RANGE": {
                    "backend": "dateFilterAllDatesInRange",
                    "frontend": objSSAgencyStringsService.getString("dateFilterAllDatesInRange")
                },
                "DATE_FILTER_ANY_DATES_IN_RANGE": {
                    "backend": "dateFilterAnyDatesInRange",
                    "frontend": objSSAgencyStringsService.getString("dateFilterAnyDatesInRange")
                }
            });

            this.set("nodeLevel", {
                "ORGANISATION": {
                    "backend": "organisation",
                    "frontend": objStrings.getString("organisation")
                },
                "SITE": {
                    "backend": "site",
                    "frontend": objSSAgencyStringsService.getString("site")
                },
                "WARD": {
                    "backend": "ward",
                    "frontend": objSSAgencyStringsService.getString("ward")
                }
            });

            this.set("complianceContractStatus", {
                "ACTIVE": {
                    "backend": "active",
                    "frontend": objStrings.getString("active")
                },
                "INACTIVE": {
                    "backend": "inactive",
                    "frontend": objStrings.getString("inactive")
                }
            });
        }
    });
});