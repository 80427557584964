define("a24-staffshift-subdomain-agency/controllers/index/agency-assignments", ["exports", "a24-staffshift-subdomain-agency/mixins/query-param-object-builder"], function (exports, _queryParamObjectBuilder) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, {
        queryParams: ["order_reference", "agency_shift_booked_candidate_full_name", "agency_shift_booked_candidate_payroll_number", "order_site_id", "order_organisation_id", "order_ward_name", "agency_shift_reference_number", "order_po_number", "order_job_family_id", "order_job_title_id", "shift_start_date", "shift_start_dateFrom", "shift_start_dateTo", "shift_end_date", "shift_end_dateFrom", "shift_end_dateTo", "agency_shift_booked_minutes", "agency_order_contract_id", "agency_shift_status_code", "time_interval_shift_reference", "time_interval_total_billable_minutes", "time_interval_total_non_billable_minutes", "time_interval_start_date", "time_interval_start_dateFrom", "time_interval_start_dateTo", "time_interval_end_date", "time_interval_end_dateFrom", "time_interval_end_dateTo", "time_interval_confirmed", "time_interval_authorised", "time_interval_status", "time_interval_contract_pay", "timesheet_number", "timesheet_candidate_name", "timesheet_candidate_agency_reference", "timesheet_ward_name", "timesheet_ward_reference", "timesheet_po_number", "timesheet_job_family_id", "timesheet_job_title_id", "period_end_date", "ward_change", "job_title_change", "timesheet_contract_id", "contract_change", "timesheet_status", "timesheet_contract_total_expense_pay", "timesheet_contract_total_gross_pay", "timesheet_contract_total_gross_charge_incl_vat", "minutes_difference", "agency_shift_booked_assignment_created_date", "agency_shift_booked_assignment_created_dateFrom", "agency_shift_booked_assignment_created_dateTo", "agency_shift_booked_assignment_created_user_fullname", "agency_shift_booked_assignment_created_user_id", "agency_shift_booked_assignment_created_user_username", "page", "sortBy", "vis_cols"],
        order_reference: "",
        agency_shift_booked_candidate_full_name: "",
        agency_shift_booked_candidate_payroll_number: "",
        order_site_name: "",
        order_organisation_name: "",
        order_ward_name: "",
        agency_shift_reference_number: "",
        order_po_number: "",
        order_job_family_id: "",
        order_job_title_id: "",
        // Default filter for today
        shift_start_date: "W3siYkVuY29kZSI6dHJ1ZSwiYkZyb21BbmRUbyI6dHJ1ZSwibVZhbHVlIjp7InNGcm9tIjoiMjAxOS0xMi0wOVQwMDowMDowMCswMjowMCIsInNUbyI6IjIwMTktMTItMDlUMjM6NTk6NTkrMDI6MDAifSwic0ZpbHRlclZhbHVlIjoiZGF0ZUZpbHRlclRvZGF5In1d",
        shift_start_dateFrom: "",
        shift_start_dateTo: "",
        shift_end_date: "",
        shift_end_dateFrom: "",
        shift_end_dateTo: "",
        agency_shift_booked_minutes: "",
        agency_order_contract_id: "",
        agency_shift_status_code: "Accepted",
        time_interval_shift_reference: "",
        time_interval_total_billable_minutes: "",
        time_interval_total_non_billable_minutes: "",
        time_interval_start_date: "",
        time_interval_start_dateFrom: "",
        time_interval_start_dateTo: "",
        time_interval_end_date: "",
        time_interval_end_dateFrom: "",
        time_interval_end_dateTo: "",
        time_interval_confirmed: "",
        time_interval_authorised: "",
        time_interval_status: "New|Confirmed|Authorised|Approved|Closed",
        time_interval_contract_pay: "",
        timesheet_number: "",
        timesheet_candidate_name: "",
        timesheet_candidate_agency_reference: "",
        timesheet_ward_name: "",
        timesheet_ward_reference: "",
        timesheet_po_number: "",
        timesheet_job_family_id: "",
        timesheet_job_title_id: "",
        period_end_date: "",
        ward_change: "",
        job_title_change: "",
        timesheet_contract_id: "",
        contract_change: "",
        timesheet_status: "",
        timesheet_contract_total_expense_pay: "",
        timesheet_contract_total_gross_pay: "",
        timesheet_contract_total_gross_charge_incl_vat: "",
        minutes_difference: "",
        agency_shift_booked_assignment_created_date: "",
        agency_shift_booked_assignment_created_dateFrom: "",
        agency_shift_booked_assignment_created_dateTo: "",
        agency_shift_booked_assignment_created_user_fullname: "",
        agency_shift_booked_assignment_created_user_id: "",
        agency_shift_booked_assignment_created_user_username: "",
        page: "1",
        sortBy: "shift_start_date::asc",
        vis_cols: "1,25,2,27,4,32,10,19,20,23"
    });
});