define("a24-staffshift-subdomain-agency/controllers/index/agency-linked-client-search", ["exports", "a24-staffshift-subdomain-agency/mixins/query-param-object-builder"], function (exports, _queryParamObjectBuilder) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, {
        queryParams: ["organisation_id", "organisation_name", "site_id", "site_name", "ward_id", "ward_name", "node_level", "client_reference", "site_type_id", "site_type_name", "ward_type_id", "ward_type_name", "site_address_line_1", "site_address_line_2", "site_province", "site_post_code", "site_city", "site_country_code", "site_reference_number", "ward_reference_number", "site_physical_geo_location", "compliance_contract_id", "compliance_contract_name", "compliance_contract_status", "page", "sortBy", "vis_cols"],
        organisation_id: "",
        organisation_name: "",
        site_id: "",
        site_name: "",
        ward_id: "",
        ward_name: "",
        node_level: "site|ward",
        client_reference: "",
        site_type_id: "",
        site_type_name: "",
        ward_type_id: "",
        ward_type_name: "",
        site_address_line_1: "",
        site_address_line_2: "",
        site_province: "",
        site_post_code: "",
        site_city: "",
        site_country_code: "",
        site_reference_number: "",
        ward_reference_number: "",
        site_physical_geo_location: "",
        compliance_contract_id: "",
        compliance_contract_name: "",
        compliance_contract_status: "",
        page: "1",
        sortBy: "site_name::asc",
        vis_cols: "1,3,5,6,7,8,9,13"
    });
});