define("a24-staffshift-subdomain-agency/components/page-agency-list-compliance-contract", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _didRenderChangesMixin, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        objQueryParams: null,
        bDatagridLoading: false,

        _bPageLoading: true,

        _objSSAgencyStrings: Ember.inject.service("a24-strings-staffshift-agency"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSSAgencyEnums: Ember.inject.service("a24-enums-staffshift-agency"),
        _objUserSession: Ember.inject.service("user-session"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigation: Ember.inject.service("navigation"),

        init: function init() {
            this._super.apply(this, arguments);

            this.get("_objTitleBarService").setTitleBar(this.get("_objSSAgencyStrings").getString("complianceContracts"));

            // Build up the url with context id.
            this.set("_sUrlBase", _environment.default.sRestApiBaseUrl + "/v1/agency/" + this.get("_objNavigation.sContextId") + "/compliance-contract");

            this._setupPageConfig();
        },

        _setupPageConfig: function _setupPageConfig() {

            var arrComplianceContractStatus = this.get("_objSSAgencyEnums").getList("complianceContractStatus");
            var arrComplianceContractStatusDropdown = [];
            for (var y = 0; y < arrComplianceContractStatus.length; y++) {
                arrComplianceContractStatusDropdown.push({
                    sTitle: arrComplianceContractStatus[y].sLabel,
                    sValue: arrComplianceContractStatus[y].sValue
                });
            }

            // This array contain the datagrid columns
            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objSSAgencyStrings").getString("contract"),
                sProperty: "name",
                sFilterType: "text",
                bText: true,
                bHideEmptyCheck: true,
                iWidth: 15
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objStringsService").getString("description"),
                sProperty: "description",
                sFilterType: "text",
                bText: true,
                bStopFiltering: true,
                bStopColumnSort: true,
                iWidth: 15
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objStringsService").getString("status"),
                sProperty: "status",
                sFilterType: "dropdown",
                iWidth: 5,
                bMapping: true,
                bHideEmptyCheck: true,
                arrMappingData: arrComplianceContractStatusDropdown
            }];

            var arrSingleItemActions = [];

            var arrMultiItemActions = [];

            this.set("_objDatagridConfig", {
                sDatagridHeader: this.get("_objSSAgencyStrings").getString("complianceContracts"),
                iMaxHeight: 40 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bHasReloadButton: true,
                arrSingleItemSelectActions: arrSingleItemActions,
                arrMultiItemSelectActions: arrMultiItemActions,
                arrHeaderConfig: arrDataGridHeaderConfig
            });
            this.set("_bPageLoading", false);
        },
        actions: {
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {},
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected() /*arrSelectedItems*/{},
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected() /*arrSelectedItems*/{},
            cardDatagridTableCell: function cardDatagridTableCell() /*sColumnName, objRowData*/{}
        }
    });
});