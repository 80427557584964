define("a24-staffshift-subdomain-agency/components/page-agency-candidate-search", ["exports", "a24-staffshift-subdomain-agency/config/environment", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _environment, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        objQueryParams: null,

        _bPageLoading: true,
        _bShowCandidateAddPopup: false,
        _bShowPossibleNonShiftsPopup: false,

        _bShowLinkedInfoPopup: false,
        _bLinkedInfoPopupLoading: false,
        _sLinkedInfoHeaderPopup: "",
        _objLinkedInfoSpecialPopupAction: null,
        _objAgencyCandidateProfileAgencyInfoPromise: null,

        _bShowPossibleShiftsPopup: false,
        _sPossibleShiftNotifPreview: "",
        _arrPossibleShifts: null,
        _sPossibleShiftNotifErrorMessage: "",
        _bShowSentMessagePopup: false,

        _sUrlBase: "",

        _objDatagridConfig: null,
        _objCandidateTypesResponse: null,
        _objSelectedItem: null,

        _arrCandidateTypes: null,
        _arrCandidateStatus: null,
        _arrSelectedItems: null,

        // Services
        _objUserSession: Ember.inject.service("user-session"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigation: Ember.inject.service("navigation"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objEmberLibString: Ember.inject.service("a24-strings-ember-lib"),
        _objFabButtonService: Ember.inject.service("fab-button"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objAvailabilityStrings: Ember.inject.service("a24-strings-staffshift-availability"),
        _objSSCoreStringsService: Ember.inject.service("a24-strings-staffshift-core"),
        _objSSUsersStringsService: Ember.inject.service("a24-strings-staffshift-users"),
        _objSSAgencyStringsService: Ember.inject.service("a24-strings-staffshift-agency"),
        _objRestCandidateType: Ember.inject.service("rest-agency-candidate-type"),
        _objRestCandidate: Ember.inject.service("rest-agency-candidate"),
        _objRestSystem: Ember.inject.service("rest-system"),
        _objSSAgencyEnumsService: Ember.inject.service("a24-enums-staffshift-agency"),
        _objSSCoreEnum: Ember.inject.service("a24-enums-staffshift-core"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objConstantsLib: Ember.inject.service("a24-constants-ember-lib"),
        _objFilterCustomService: Ember.inject.service("card-datagrid-custom-filter-availability"),
        _objFilterCustomCandidatePositionHeldService: Ember.inject.service("card-datagrid-custom-filter-candidate-position-held"),
        _objFilterCustomCandidateDistanceService: Ember.inject.service("card-datagrid-custom-filter-candidate-distance"),
        _objFilterCustomCandidateProfessionalRegistrationService: Ember.inject.service("card-datagrid-custom-filter-candidate-professional-registration"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objRestAgencyStatus: Ember.inject.service("rest-agency-status-details"),
        _objRestAgencyCandidateProfileAgencyInfo: Ember.inject.service("rest-agency-candidate-profile-agency-info"),
        _objRestActivityType: Ember.inject.service("rest-advanced-search-activity-type"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objRestAgencyJobTitle: Ember.inject.service("rest-agency-job-title"),
        _objRestAgencyShift: Ember.inject.service("rest-agency-advanced-search-agency-shift"),
        _objAgencySavedSearchService: Ember.inject.service("rest-agency-saved-searches"),
        _objLinkedClientsService: Ember.inject.service("rest-agency-site"),
        _objRestAgencyShiftPromise: null,
        _objRestBulkNotificationsService: Ember.inject.service("rest-agency-candidate-bulk-notifications"),
        _objEnumEmberLibService: Ember.inject.service("a24-enums-ember-lib"),
        _objRestBulkNotificationsPromise: null,
        _bShowLoadFailedPopup: false,
        _objSystemSkillsResponse: null,
        _objPossibleShiftSpecialPopupAction: null,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.get("_objTitleBarService").setTitleBar(this.get("_objSSCoreStringsService").getString("candidateSearch"));

            // Build up the url with context id.
            this.set("_sUrlBase", _environment.default.sRestApiBaseUrl + "/v1/agency/" + this.get("_objNavigation.sContextId") + "/advanced-search/candidate");

            this.set("_objFilterCustomCandidatePositionHeldService._objJobTitleService", this.get("_objRestAgencyJobTitle"));

            this.set("_objLinkedInfoSpecialPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._popupLinkedInfoClose();
                }
            });

            this.set("_objPossibleShiftSpecialPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._closePossibleShiftsPopup();
                }
            });

            this.doServiceCalls();
        },

        _handleServerErrors: function _handleServerErrors() {
            this.set("_bShowLoadFailedPopup", true);
        },

        doServiceCalls: function doServiceCalls() {
            var _this2 = this;

            this.set("_bPageLoading", true);
            var objPromiseCandidateTypes = this.get("_objRestCandidateType").getCandidateTypes(this, "_objCandidateTypesResponse", this.get("_objUserSession").getUserContextReference().mId, function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2._handleServerErrors();
            }));

            var objPromiseCandidateStatus = this.get("_objRestAgencyStatus").getStatusList(this, "_objCandidateStatusResponse", function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2._handleServerErrors();
            }));

            var objActivityTypesQueryParams = {
                disabled: { mValue: false },
                name: { mValue: "asc", bSortBy: true },
                page: { mValue: 1 },
                items_per_page: { mValue: 1000 }
            };

            var objPromiseActivityTypes = this.get("_objRestActivityType").getActivityTypes(this, "_objActivityTypeResponse", function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2._handleServerErrors();
            }), objActivityTypesQueryParams);

            var objPromiseList = {
                _objRSVPCandidateTypes: objPromiseCandidateTypes,
                _objRSVPCandidateStatus: objPromiseCandidateStatus,
                _objRSVPActivityTypes: objPromiseActivityTypes
            };
            var arrRequiredResponses = ["_objRSVPCandidateTypes", "_objRSVPCandidateStatus", "_objRSVPActivityTypes"];

            if (!a24Core.isEmpty(this.get("_objNavigation.objRouteQueryParams.pos_shift_order_id"))) {
                var objPromiseAgencyShifts = this.get("_objRestAgencyShift").getShifts(this, "_objRestAgencyShiftPromise", {
                    order_id: {
                        bEncode: true,
                        mValue: this.get("_objNavigation.objRouteQueryParams.pos_shift_order_id")
                    }
                },
                //TODO add filter for end date > today
                //TODO add filter for status "New|Reopened",
                //The above 2 TODO's will need to be done manually for now since we need at
                //at least one shift to come back so that we can apply the goe/city filter correct
                { start_date: { mValue: "asc", bSortBy: true } }, 1, 1000, function () {
                    // Handled by RSVP
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    _this2._handleServerErrors();
                }));

                objPromiseList._objRSVPAgencyShift = objPromiseAgencyShifts;
                arrRequiredResponses.push("_objRSVPAgencyShift");
            }

            var objThis = this;

            // Adding a rsvp here for when we do the statuses later on so that we dont have to add it
            a24RSVP.configure(this, objPromiseList, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    var arrCandidateTypes = objResponses._objRSVPCandidateTypes.objResponse;
                    var arrCandidateStatus = objResponses._objRSVPCandidateStatus.objResponse;
                    var arrActivityTypes = objResponses._objRSVPActivityTypes.objResponse;
                    var arrAgencyShifts = [];
                    if (!a24Core.isEmpty(objResponses._objRSVPAgencyShift)) {
                        arrAgencyShifts = objResponses._objRSVPAgencyShift.objResponse;
                    }

                    if (a24Core.isEmpty(arrCandidateTypes)) {
                        arrCandidateTypes = [];
                    }
                    if (a24Core.isEmpty(arrCandidateStatus)) {
                        arrCandidateStatus = [];
                    }
                    if (a24Core.isEmpty(arrActivityTypes)) {
                        arrActivityTypes = [];
                    }
                    if (a24Core.isEmpty(objResponses._objRSVPAgencyShift)) {
                        objThis.setupPageConfig(arrCandidateTypes, arrCandidateStatus, arrActivityTypes, null);
                    } else {
                        if (a24Core.isEmpty(arrAgencyShifts)) {
                            objThis._handleServerErrors();
                        } else {
                            objThis._buildPossibleShiftNotif(arrAgencyShifts);
                            objThis.setupPageConfig(arrCandidateTypes, arrCandidateStatus, arrActivityTypes, arrAgencyShifts[0]);
                        }
                    }
                }
            }]);
        },
        _buildPossibleShiftNotif: function _buildPossibleShiftNotif(arrData) {
            var objShiftItem = arrData[0];

            var objDateInstance = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            var objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            var sEndTime = void 0;
            var sStartTime = void 0;
            var arrPayRates = [];

            //TODO cater for overlap to next day
            var sShiftDateTime = this.get("_objSSAgencyStringsService").getString("shiftsAvailable") + ": ";
            var arrPossibleShifts = [];
            for (var i = 0; i < arrData.length; i++) {
                objDateInstance.setDateFromRest(arrData[i].end_date);

                if (objDateInstance.isAfter(objCurrentDate) && (arrData[i].status_code === this.get("_objSSCoreEnum.assignmentStatus.NEW.backend") || arrData[i].status_code === this.get("_objSSCoreEnum.assignmentStatus.REOPENED.backend"))) {
                    arrPossibleShifts.push(arrData[i]);
                    sEndTime = objDateInstance.getTimeFormat();
                    objDateInstance.setDateFromRest(arrData[i].start_date);
                    sStartTime = objDateInstance.getTimeFormat();
                    sShiftDateTime += objDateInstance.getDateFormat() + " " + sStartTime + "-" + sEndTime + ", ";

                    if (!a24Core.isEmpty(arrData[i].contract_breakdown)) {
                        for (var j = 0; j < arrData[i].contract_breakdown.length; j++) {
                            if (!a24Core.isEmpty(arrData[i].contract_breakdown[j].pay_rate)) {
                                arrPayRates.push(arrData[i].contract_breakdown[j].pay_rate);
                            }
                        }
                    }
                }
            }

            if (a24Core.isEmpty(arrPossibleShifts)) {
                return;
            } else {
                this.set("_arrPossibleShifts", arrPossibleShifts);
            }

            var sAddress = "";
            if (!a24Core.isEmpty(objShiftItem.city)) {
                sAddress = objShiftItem.city + ", ";
            }
            if (!a24Core.isEmpty(objShiftItem.post_code) && !a24Core.isEmpty(objShiftItem.country_code) && (objShiftItem.country_code.toLowerCase() === "gb" || objShiftItem.country_code.toLowerCase() === "uk")) {
                sAddress += objShiftItem.post_code.split(" ")[0];
            } else if (!a24Core.isEmpty(objShiftItem.post_code)) {
                sAddress += objShiftItem.post_code;
            }

            var sPayRate = "";

            if (!a24Core.isEmpty(arrPayRates)) {
                sPayRate = ". " + this.get("_objSSAgencyStringsService").getString("payRate") + ": ";
                arrPayRates.sort(function (a, b) {
                    return a - b;
                });

                var sFirstRate = arrPayRates[0].toFixed(2);
                var sLastRate = arrPayRates[arrPayRates.length - 1].toFixed(2);
                var sUnit = objShiftItem.contract_unit === "hourly" ? "p/h" : "";

                sPayRate += objShiftItem.contract_currency + sFirstRate + sUnit;

                if (sFirstRate !== sLastRate) {
                    sPayRate += " - " + objShiftItem.contract_currency + sLastRate + sUnit;
                }
            }

            var sReplyWith = ". " + this.get("_objSSAgencyStringsService").getString("replyWithOrder") + " #" + objShiftItem.order_reference;
            var sAgency = ". " + this.get("_objUserSession.objContextDetails.sDisplayName");

            this.set("_sPossibleShiftNotifPreview", sShiftDateTime + sAddress + sPayRate + sReplyWith + sAgency);
        },
        setupPageConfig: function setupPageConfig(arrCandidateTypes, arrCandidateStatus, arrActivityTypes, objShiftWithOrderDetails) {
            var _this3 = this;

            var objThis = this;

            this.set("_arrCandidateTypes", arrCandidateTypes);
            this.set("_arrCandidateStatus", arrCandidateStatus);
            this.set("_arrActivityTypes", arrActivityTypes);

            var arrLanguageOptions = objThis.get("_objCandidateProfileEnumsService").getList("language");
            var arrMediumTypeOptions = objThis.get("_objCandidateProfileEnumsService").getList("mediumTypeDnc");
            var arrTransportOptions = objThis.get("_objCandidateProfileEnumsService").getList("transportType");

            var arrCandidateTypesDropdown = [];
            var arrCandidateTypeIds = [];
            for (var i = 0; i < arrCandidateTypes.length; i++) {
                arrCandidateTypeIds.push(arrCandidateTypes[i]._id);
                arrCandidateTypesDropdown.push({
                    sTitle: arrCandidateTypes[i].name,
                    sValue: arrCandidateTypes[i]._id
                });
            }

            var arrStatusesDropdown = [];
            for (var j = 0; j < arrCandidateStatus.length; j++) {
                arrStatusesDropdown.push({
                    sTitle: arrCandidateStatus[j].name,
                    sValue: arrCandidateStatus[j].name // This is done to comply with api
                });
            }

            var arrLanguagesDropdown = [];
            for (var k = 0; k < arrLanguageOptions.length; k++) {
                arrLanguagesDropdown.push({
                    sTitle: arrLanguageOptions[k].sLabel,
                    sValue: arrLanguageOptions[k].sLabel // This is done to comply with api
                });
            }

            var arrActivityTypeDropdown = [];
            for (var l = 0; l < arrActivityTypes.length; l++) {
                arrActivityTypeDropdown.push({
                    sTitle: arrActivityTypes[l].name,
                    sValue: arrActivityTypes[l].code
                });
            }

            var arrCandidateStatusesDropdown = [];
            arrCandidateStatusesDropdown.push({
                sTitle: objThis.get("_objEmberLibString").getString("empty"),
                sValue: "null"
            });
            for (var r = 0; r < arrCandidateStatus.length; r++) {
                arrCandidateStatusesDropdown.push({
                    sTitle: arrCandidateStatus[r].name,
                    sValue: arrCandidateStatus[r].name // This is done to comply with api
                });
            }

            var arrMediaTypesDropdown = [];
            for (var s = 0; s < arrMediumTypeOptions.length; s++) {
                arrMediaTypesDropdown.push({
                    sTitle: arrMediumTypeOptions[s].sLabel,
                    sValue: arrMediumTypeOptions[s].sValue
                });
            }

            var arrTransportOptionsDropdown = [];
            for (var t = 0; t < arrTransportOptions.length; t++) {
                arrTransportOptionsDropdown.push({
                    sTitle: arrTransportOptions[t].sLabel,
                    sValue: arrTransportOptions[t].sValue
                });
            }

            var arrQueryType = this.get("_objEnumEmberLibService").getList("filterOptionsTextType");
            var objConstants = this.get("_objConstantsLib");
            arrQueryType.push({
                "sValue": objConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_CONTAINS,
                "sLabel": this.get("_objStringsService").getString("contains")
            });

            // This array contain the datagrid columns
            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objSSCoreStringsService").getString("fullName"),
                sProperty: "full_name",
                sFilterType: "text",
                bText: true,
                iWidth: 15,
                bIsClickable: true,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objStringsService").getString("firstName"),
                sProperty: "first_name",
                sFilterType: "text",
                bText: true,
                iWidth: 8,
                bIsClickable: true,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objStringsService").getString("lastName"),
                sProperty: "last_name",
                sFilterType: "text",
                bText: true,
                iWidth: 8,
                bIsClickable: true,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 4,
                sTitle: this.get("_objStringsService").getString("gender"),
                sProperty: "gender",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: [{
                    sValue: "male",
                    sTitle: this.get("_objStringsService").getString("male")
                }, {
                    sValue: "female",
                    sTitle: this.get("_objStringsService").getString("female")
                }],
                iWidth: 8
            }, {
                iUniqueColNum: 5,
                sTitle: this.get("_objSSCoreStringsService").getString("type"),
                sProperty: "candidate_type_id",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: arrCandidateTypesDropdown,
                iWidth: 15
            }, {
                iUniqueColNum: 6,
                sTitle: this.get("_objCandidateProfileStringsService").getString("currentStatus"),
                sProperty: "current_status__status",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: arrCandidateStatusesDropdown,
                iWidth: 15
            }, {
                iUniqueColNum: 7,
                sTitle: this.get("_objCandidateProfileStringsService").getString("currentStatusSince"),
                sProperty: "current_status__date",
                sFilterType: "date",
                bDate: true,
                iWidth: 15
            }, {
                iUniqueColNum: 8,
                sTitle: this.get("_objCandidateProfileStringsService").getString("linkedToAgencySince"),
                sProperty: "linked_to_agency_at",
                sFilterType: "date",
                bDate: true,
                iWidth: 15
            }, {
                iUniqueColNum: 9,
                sTitle: this.get("_objSSAgencyStringsService").getString("city"),
                sProperty: "city",
                sFilterType: "text",
                bText: true,
                iWidth: 15,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 10,
                sTitle: this.get("_objCandidateProfileStringsService").getString("county"),
                sProperty: "province",
                sFilterType: "text",
                bText: true,
                iWidth: 15,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 11,
                sTitle: this.get("_objSSAgencyStringsService").getString("postCode"),
                sProperty: "post_code",
                sFilterType: "text",
                bText: true,
                iWidth: 8,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 12,
                sTitle: this.get("_objStringsService").getString("username"),
                sProperty: "username",
                sFilterType: "text",
                bText: true,
                iWidth: 8,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 16,
                sTitle: this.get("_objCandidateProfileStringsService").getString("payrollNumber"),
                sProperty: "employment_reference_number",
                sFilterType: "text",
                bText: true,
                iWidth: 8
            }, {
                iUniqueColNum: 13,
                sTitle: this.get("_objStringsService").getString("email"),
                sProperty: "emails___email",
                sFilterType: "text",
                bText: true,
                bSilent: true,
                iWidth: 8,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 14,
                sTitle: this.get("_objStringsService").getString("contactNumber"),
                sProperty: "contact_numbers___number",
                sFilterType: "text",
                bText: true,
                bSilent: true,
                iWidth: 8,
                funcFilterParseQueryValue: function funcFilterParseQueryValue(objQueryObject) {
                    var arrValids = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
                    var sSearchFilter = "";
                    var bFirstChar = true;

                    // Doing a null string check because for some reason the mValue is a string
                    if (!a24Core.isEmpty(objQueryObject.mValue) && objQueryObject.mValue !== "null") {

                        // Loops over the mValue to build up a string without invalid chars for the filter
                        // Also replaces the invalid chars with a *
                        for (var i = 0; i < objQueryObject.mValue.length; i++) {
                            if (arrValids.indexOf(objQueryObject.mValue.charAt(i)) !== -1) {
                                if (bFirstChar) {
                                    // If the first char is a 0 then it will strip it off
                                    if (objQueryObject.mValue.charAt(i) !== "0") {
                                        sSearchFilter += objQueryObject.mValue.charAt(i);
                                    }
                                    bFirstChar = false;
                                } else {
                                    sSearchFilter += objQueryObject.mValue.charAt(i);
                                }
                            } else {
                                sSearchFilter += "*";
                            }
                        }

                        objQueryObject.mValue = sSearchFilter;
                    }
                    return objQueryObject;
                }
            }, {
                iUniqueColNum: 15,
                sTitle: this.get("_objStringsService").getString("language"),
                sProperty: "languages___name",
                sFilterType: "dropdown",
                bSilent: true,
                bMapping: true,
                arrMappingData: arrLanguagesDropdown,
                iWidth: 8,
                bFilterSecondary: true
            }, {
                iUniqueColNum: 17,
                sTitle: this.get("_objSSAgencyStringsService").getString("availabilityLastUpdated"),
                sProperty: "availability_last_updated_at",
                sFilterType: "date",
                bDate: true,
                iWidth: 15
            }, {
                iUniqueColNum: 18,
                sTitle: this.get("_objSSAgencyStringsService").getString("availability"),
                sProperty: "availability___status",
                sFilterType: "custom",
                bStopColumnSort: true,
                iWidth: 15,
                bFilterAnd: true,
                bFilterOr: true,
                iMinWidth: 220,

                bCustom: true,
                sTableCustomComponent: "card-datagrid-table-cell-custom-availability",

                sFilterCustomComponentName: "card-datagrid-custom-filter-availability",
                objFilterCustomService: this.get("_objFilterCustomService")
            }, {
                iUniqueColNum: 19,
                sTitle: this.get("_objCandidateProfileStringsService").getString("distance"),
                sProperty: "physical_address_geo_location",
                bText: true,
                sFilterType: "custom",
                bSilent: true,
                iWidth: 15,
                bHideEmptyCheck: true,
                sContext: this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY"),
                objLinkedClientsService: this.get("_objLinkedClientsService"),
                sFilterCustomComponentName: "card-datagrid-custom-filter-candidate-distance",
                objFilterCustomService: this.get("_objFilterCustomCandidateDistanceService")
            }, {
                iUniqueColNum: 20,
                sTitle: this.get("_objSSAgencyStringsService").getString("positionHeld"),
                sProperty: "employment_position_held___job_family_id",
                bText: true,
                sFilterType: "custom",
                bSilent: true,
                iWidth: 15,
                bFilterAnd: true,
                bFilterOr: true,

                sFilterCustomComponentName: "card-datagrid-custom-filter-candidate-position-held",
                objFilterCustomService: this.get("_objFilterCustomCandidatePositionHeldService")
            }, {
                iUniqueColNum: 21,
                sTitle: this.get("_objSSCoreStringsService").getString("lastShiftDate"),
                sProperty: "last_shift_worked_at",
                sFilterType: "date",
                bDate: true,
                iWidth: 15
            }, {
                iUniqueColNum: 22,
                sTitle: this.get("_objSSCoreStringsService").getString("nextShiftDate"),
                sProperty: "next_shift_at",
                sFilterType: "date",
                bDate: true,
                iWidth: 15
            }, {
                iUniqueColNum: 23,
                sTitle: this.get("_objSSCoreStringsService").getString("lastLoggedIn"),
                sProperty: "last_login_at",
                sFilterType: "date",
                bDate: true,
                iWidth: 15
            }, {
                iUniqueColNum: 25,
                sTitle: this.get("_objCandidateProfileStringsService").getString("activities"),
                sProperty: "activities___activity_type",
                sFilterType: "dropdown",
                bSilent: true,
                bMapping: true,
                arrMappingData: arrActivityTypeDropdown,
                iWidth: 8,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true
            }, {
                iUniqueColNum: 35,
                sTitle: this.get("_objCandidateProfileStringsService").getString("tags"),
                sProperty: "tags_links___tag_id",
                sFilterType: "suggest",
                sReactFilterRoute: "datagrid/filterEditor/candidate-tags",
                iWidth: 15,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true
            }, {
                iUniqueColNum: 26,
                sTitle: this.get("_objCandidateProfileStringsService").getString("skill"),
                sProperty: "skill_details___skill_id",
                sFilterType: "suggest",
                sReactFilterRoute: "datagrid/filterEditor/skills",
                //Whatever property is listed in the arrReactFilterExtraDataPropswill be pulled from
                //_objDatagridConfig config and send on to react popup
                arrReactFilterExtraDataProps: ["candidate_types"],
                iWidth: 15,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true,
                funcFilterParseQueryValue: function funcFilterParseQueryValue(objQueryObject) {
                    var objNewQueryObject = Ember.copy(objQueryObject, true);

                    for (var _i = 0; _i < objNewQueryObject.length; _i++) {
                        if (objNewQueryObject[_i].mValue === "*") {
                            objNewQueryObject[_i].bAny = true;
                        }
                        if (!a24Core.isEmpty(objNewQueryObject[_i].arSec)) {
                            for (var _j = 0; _j < objNewQueryObject[_i].arSec.length; _j++) {
                                if (objNewQueryObject[_i].arSec[_j].sProp === "duration") {
                                    objNewQueryObject[_i].arSec[_j].mValue = Math.ceil(objNewQueryObject[_i].arSec[_j].mValue * 12);
                                }
                            }
                        }
                    }

                    return objNewQueryObject;
                }
            }, {
                iUniqueColNum: 27,
                sTitle: this.get("_objCandidateProfileStringsService").getString("qualification"),
                sProperty: "qualification_details___qualification_id",
                sFilterType: "suggest",
                sReactFilterRoute: "datagrid/filterEditor/qualifications",
                //Whatever property is listed in the arrReactFilterExtraDataProps will be pulled from
                //_objDatagridConfig config and send on to react popup
                arrReactFilterExtraDataProps: ["candidate_types"],
                iWidth: 15,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true,
                funcFilterParseQueryValue: function funcFilterParseQueryValue(objQueryObjectParam) {
                    var objQueryObject = Ember.copy(objQueryObjectParam, true);
                    for (var _i2 = 0; _i2 < objQueryObject.length; _i2++) {
                        if (objQueryObject[_i2].mValue === "*") {
                            objQueryObject[_i2].bAny = true;
                        }
                    }

                    return objQueryObject;
                }
            }, {
                iUniqueColNum: 28,
                sTitle: this.get("_objCandidateProfileStringsService").getString("professionalRegistration"),
                sProperty: "registration_body_details___registration_body_id",
                bText: true,
                sFilterType: "custom",
                sReactFilterRoute: "datagrid/filterEditor/professional-registration",
                //Whatever property is listed in the arrReactFilterExtraDataProps will be pulled from
                //_objDatagridConfig config and send on to react popup
                arrReactFilterExtraDataProps: ["candidate_types"],
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                iWidth: 15,
                bHideEmptyCheck: true,
                sFilterCustomComponentName: "card-datagrid-custom-filter-candidate-professional-registration",
                objFilterCustomService: this.get("_objFilterCustomCandidateProfessionalRegistrationService")
            }, {
                iUniqueColNum: 33,
                sTitle: this.get("_objCandidateProfileStringsService").getString("profRegEntries"),
                sProperty: "registration_body_details___registration_body_entry_id",
                bText: true,
                sFilterType: "custom",
                sReactFilterRoute: "datagrid/filterEditor/professional-registration-entry",
                //Whatever property is listed in the arrReactFilterExtraDataProps will be pulled from
                //_objDatagridConfig config and send on to react popup
                arrReactFilterExtraDataProps: ["candidate_types"],
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                iWidth: 15,
                bHideEmptyCheck: true,
                sFilterCustomComponentName: "card-datagrid-custom-filter-candidate-professional-registration",
                objFilterCustomService: this.get("_objFilterCustomCandidateProfessionalRegistrationService")
            }, {
                iUniqueColNum: 29,
                sTitle: this.get("_objSSCoreStringsService").getString("keywords"),
                sProperty: "keywords___name",
                sFilterType: "text",
                iWidth: 15,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 30,
                sTitle: this.get("_objCandidateProfileStringsService").getString("lastSeenInApp"),
                sProperty: "chat_token_last_issued_at",
                sFilterType: "date",
                bDate: true,
                iWidth: 15
            }, {
                iUniqueColNum: 31,
                sTitle: this.get("_objCandidateProfileStringsService").getString("doNotContact"),
                sProperty: "notification_dnc",
                sFilterType: "bool",
                bHideEmptyCheck: true,
                bBool: true,
                bMapping: true,
                arrMappingData: [{
                    sValue: "true",
                    sTitle: this.get("_objStringsService").getString("yes")
                }, {
                    sValue: "false",
                    sTitle: this.get("_objStringsService").getString("no")
                }],
                iWidth: 8
            }, {
                iUniqueColNum: 32,
                sTitle: this.get("_objCandidateProfileStringsService").getString("notificationPreference"),
                sProperty: "medium_dnc___type",
                sFilterType: "dropdown",
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true,
                bMapping: false,
                arrMappingData: arrMediaTypesDropdown,
                bCustom: true,
                arrQueryType: [{
                    sLabel: this.get("_objStringsService").getString("is"),
                    sValue: this.get("_objConstantsLib").CARD_DATAGRID_FILTER_QUERY_TYPE_IS
                }],
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    var sMediumTypes = "";
                    if (!a24Core.isEmpty(objRowData.medium_dnc)) {
                        var arrRowMediumDncData = objRowData.medium_dnc;
                        for (var _i3 = 0; _i3 < arrRowMediumDncData.length; _i3++) {
                            if (!arrRowMediumDncData[_i3].value) {
                                var sMediumType = _this3.get("_objCandidateProfileEnumsService").getFrontendValue("mediumTypeDnc", arrRowMediumDncData[_i3].type);
                                if (a24Core.isEmpty(sMediumType)) {
                                    sMediumType = arrRowMediumDncData[_i3].type;
                                }
                                if (!a24Core.isEmpty(sMediumTypes)) {
                                    sMediumTypes += ", ";
                                }
                                sMediumTypes += sMediumType;
                            }
                        }
                    }
                    return Ember.String.htmlSafe("<span title=\"" + sMediumTypes + "\">" + sMediumTypes + "</span>");
                },
                iWidth: 15
            }, {
                iUniqueColNum: 34,
                sTitle: this.get("_objCandidateProfileStringsService").getString("transport"),
                sProperty: "transport_type",
                sFilterType: "dropdown",
                bHideEmptyCheck: false,
                bSilent: true,
                bMapping: false,
                arrMappingData: arrTransportOptionsDropdown,
                iWidth: 8
            }];

            var arrSingleItemActions = [{
                sTitle: this.get("_objSSAgencyStringsService").getString("possibleWorkNotification"),
                sIcon: "event_note",
                executeCardAction: function executeCardAction() {
                    objThis.set("_arrCandidateIds", objThis.get("_arrSelectedItems").getEach("candidate_id"));
                    objThis.set("_bShowPossibleNonShiftsPopup", true);
                }
            }, {
                sTitle: this.get("_objSSCoreStringsService").getString("openCalendarInNewTab"),
                sIcon: "today",
                executeCardAction: function executeCardAction() {
                    objThis.viewCandidateCalendarInNewWindow(objThis.get("_arrSelectedItems")[0].candidate_id);
                }
            }, {
                sTitle: this.get("_objSSCoreStringsService").getString("openInNewTab"),
                sIcon: "open_in_new",
                executeCardAction: function executeCardAction() {
                    objThis.viewCandidateInNewWindow(objThis.get("_arrSelectedItems")[0].candidate_id);
                }
            }, {
                sTitle: this.get("_objSSCoreStringsService").getString("viewCandidateCalendar"),
                sIcon: "perm_contact_calendar",
                executeCardAction: function executeCardAction() {
                    objThis.doCandidateCalendarView(objThis.get("_arrSelectedItems")[0].candidate_id);
                }
            }, {
                sTitle: this.get("_objSSCoreStringsService").getString("viewCandidate"),
                sIcon: "visibility",
                executeCardAction: function executeCardAction() {
                    objThis.doCandidateView(objThis.get("_arrSelectedItems")[0].candidate_id);
                }
            }, {
                sTitle: this.get("_objCandidateProfileStringsService").getString("linkedInfo"),
                sIcon: "info",
                executeCardAction: function executeCardAction() {
                    objThis.doCandidateLinkedInfoView(objThis.get("_arrSelectedItems")[0]);
                }
            }];

            var arrMultiItemActions = [{
                sTitle: this.get("_objSSAgencyStringsService").getString("possibleWorkNotification"),
                sIcon: "event_note",
                executeCardAction: function executeCardAction() {
                    objThis.set("_arrCandidateIds", objThis.get("_arrSelectedItems").getEach("candidate_id"));
                    objThis.set("_bShowPossibleNonShiftsPopup", true);
                }
            }, {
                sTitle: this.get("_objSSCoreStringsService").getString("openCalendarInNewTab"),
                sIcon: "today",
                executeCardAction: function executeCardAction() {
                    objThis.viewAllCandidateCalendarsInNewWindows(objThis.get("_arrSelectedItems"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    // Only show when selected items is not more than 10
                    return !a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length <= 10;
                }
            }, {
                sTitle: this.get("_objSSCoreStringsService").getString("openInNewTab"),
                sIcon: "open_in_new",
                executeCardAction: function executeCardAction() {
                    objThis.viewAllCandidatesInNewWindows(objThis.get("_arrSelectedItems"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    // Only show when selected items is not more than 10
                    return !a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length <= 10;
                }
            }];

            var arrProficiencyDropdown = this.get("_objCandidateProfileEnumsService").getList("languageProficiency").map(function (objProficiency) {
                return {
                    sTitle: objProficiency.sLabel,
                    sValue: objProficiency.sValue
                };
            });

            var arrSkillsStatusesDropdown = this.get("_objCandidateProfileEnumsService").getList("skillsStatusSearch").map(function (objSkillStatus) {
                return {
                    sTitle: objSkillStatus.sLabel,
                    sValue: objSkillStatus.sValue
                };
            });

            var arrQualificationsStatusesDropdown = this.get("_objCandidateProfileEnumsService").getList("qualificationsStatus").map(function (objQualificationsStatus) {
                return {
                    sTitle: objQualificationsStatus.sLabel,
                    sValue: objQualificationsStatus.sValue
                };
            });

            var sDatagridHeader = "";
            if (!a24Core.isEmpty(objShiftWithOrderDetails)) {
                //Header example: Possible Candidates for Order #1234 - Site Name - Ward Name
                sDatagridHeader = this.get("_objSSAgencyStringsService").getString("possibleCandidatesForOrder") + objShiftWithOrderDetails.order_reference + " - " + objShiftWithOrderDetails.site_name;

                if (!a24Core.isEmpty(objShiftWithOrderDetails.ward_name)) {
                    sDatagridHeader += " - " + objShiftWithOrderDetails.ward_name;
                }

                if (a24Core.isEmpty(objShiftWithOrderDetails.site_geo_location) || a24Core.isEmpty(objShiftWithOrderDetails.site_geo_location.coordinates)) {
                    var objCity = {
                        bEncode: true,
                        mValue: objShiftWithOrderDetails.city
                    };
                    this.set("objQueryParams.city.sValue", btoa(JSON.stringify([objCity])));
                } else {
                    var sUnit = this.get("_objSSAgencyEnumsService.distanceUnitOptions.KM.backend");
                    if (this.get("_objUserSession.objContextDetails.sCountryId") === "826") {
                        sUnit = this.get("_objSSAgencyEnumsService.distanceUnitOptions.MILES.backend");
                    }
                    var objGeo = {
                        bWithin: true,
                        sUnit: sUnit,
                        sLocNme: "",
                        fLong: objShiftWithOrderDetails.site_geo_location.coordinates[0],
                        fLat: objShiftWithOrderDetails.site_geo_location.coordinates[1],
                        iMax: 48280,
                        sSite: objShiftWithOrderDetails.site_name,
                        sOrg: objShiftWithOrderDetails.organisation_name
                    };

                    this.set("objQueryParams.physical_address_geo_location.sValue", btoa(JSON.stringify([objGeo])));
                }

                if (!a24Core.isEmpty(this.get("_arrPossibleShifts"))) {
                    arrSingleItemActions.insertAt(0, {
                        sTitle: this.get("_objSSAgencyStringsService").getString("sendAvailShiftNotif"),
                        sIcon: "event_available",
                        executeCardAction: function executeCardAction() {
                            objThis._showPossibleShiftsPopup();
                        }
                    });
                    arrMultiItemActions.insertAt(0, {
                        sTitle: this.get("_objSSAgencyStringsService").getString("sendAvailShiftNotif"),
                        sIcon: "event_available",
                        executeCardAction: function executeCardAction() {
                            objThis._showPossibleShiftsPopup();
                        }
                    });
                }
            } else {
                sDatagridHeader = this.get("_objSSCoreStringsService").getString("candidateSearch");
            }

            // Set up datagrid config
            this.set("_objDatagridConfig", {
                sDatagridHeader: sDatagridHeader,
                iMaxHeight: 62 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bHasReloadButton: true,
                arrSingleItemSelectActions: arrSingleItemActions,
                arrMultiItemSelectActions: arrMultiItemActions,
                arrHeaderConfig: arrDataGridHeaderConfig, // This array contain the datagrid columns
                sReactIFrameUrl: _environment.default.sReactGatewayUrl,
                candidate_types: arrCandidateTypes,
                objSavedSearchService: this.get("_objAgencySavedSearchService"),
                sSavedSearchRef: "agency-candidate-search",
                arrFilterSecondary_languages___name: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("languageProficiency"),
                    sProperty: "proficiency",
                    sFilterType: "dropdown",
                    bHideEmptyCheck: true,
                    arrMappingData: arrProficiencyDropdown
                }],
                arrFilterSecondary_activities___activity_type: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("lastOccurredAt"),
                    sProperty: "last_activity_at",
                    bHideEmptyCheck: true,
                    sFilterType: "date"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("numberOfOccurrences"),
                    sProperty: "total",
                    bHideEmptyCheck: true,
                    sFilterType: "int"
                }],
                arrFilterSecondary_skill_details___skill_id: [{
                    sTitle: this.get("_objStringsService").getString("status"),
                    sProperty: "status",
                    bHideEmptyCheck: true,
                    sFilterType: "dropdown",
                    arrMappingData: arrSkillsStatusesDropdown,
                    funcFilterPrimaryChange: function funcFilterPrimaryChange(sPrimaryValue, objPrimary, objSecondary) {
                        Ember.set(objSecondary, "bFilterRequired", sPrimaryValue === "*||" + objThis.get("_objStringsService").getString("any"));
                    }
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("yearsOfExperience"),
                    sProperty: "duration",
                    bHideEmptyCheck: true,
                    sFilterType: "int",
                    bFilterAllowFloat: true,
                    iFilterFloatPlaces: 1
                }],
                arrFilterSecondary_qualification_details___qualification_id: [{
                    sTitle: this.get("_objStringsService").getString("status"),
                    sProperty: "status",
                    bHideEmptyCheck: true,
                    sFilterType: "dropdown",
                    arrMappingData: arrQualificationsStatusesDropdown,
                    funcFilterPrimaryChange: function funcFilterPrimaryChange(sPrimaryValue, objPrimary, objSecondary) {
                        Ember.set(objSecondary, "bFilterRequired", sPrimaryValue === "*||" + objThis.get("_objStringsService").getString("any"));
                    }
                }],
                arrFilterSecondary_registration_body_details___registration_body_id: [{
                    sTitle: this.get("_objStringsService").getString("status"),
                    sProperty: "status_id",
                    sFilterType: "suggest"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("entry"),
                    sProperty: "registration_body_entry_id",
                    sFilterType: "suggest"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("entryStatus"),
                    sProperty: "registration_body_entry_status_id",
                    sFilterType: "suggest"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("expiresOn"),
                    sProperty: "expires_at",
                    sFilterType: "date"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("obtainedAt"),
                    sProperty: "obtained_at",
                    sFilterType: "date"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("validFrom"),
                    sProperty: "valid_at",
                    sFilterType: "date"
                }],
                arrFilterSecondary_registration_body_details___registration_body_entry_id: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("professionalRegistration"),
                    sProperty: "registration_body_id",
                    sFilterType: "suggest",
                    bFilterHideTag: true
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("entryStatus"),
                    sProperty: "registration_body_entry_status_id",
                    sFilterType: "suggest"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("expiresOn"),
                    sProperty: "expires_at",
                    sFilterType: "date"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("obtainedAt"),
                    sProperty: "obtained_at",
                    sFilterType: "date"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("validFrom"),
                    sProperty: "valid_at",
                    sFilterType: "date"
                }],
                arrFilterSecondary_medium_dnc___type: [{
                    sTitle: this.get("_objSSUsersStringsService").getString("enabled"),
                    sProperty: "value",
                    bHideEmptyCheck: true,
                    sFilterType: "bool",
                    bBool: true,
                    bFilterRequired: true,
                    arrMappingData: [{
                        sValue: "false",
                        sTitle: this.get("_objStringsService").getString("yes")
                    }, {
                        sValue: "true",
                        sTitle: this.get("_objStringsService").getString("no")
                    }]
                }]
            });

            this.set("_bPageLoading", false);
        },
        onItemsSelect: function onItemsSelect(arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedItem", arrSelectedItems[0]);
            }
            // Set the selected items
            this.set("_arrSelectedItems", arrSelectedItems);
        },
        doCandidateLinkedInfoView: function doCandidateLinkedInfoView(objCandidate) {
            var _this4 = this;

            Ember.run.next(function () {
                // Open linked info popup
                _this4.set("_bLinkedInfoPopupLoading", true);
                _this4.set("_bShowLinkedInfoPopup", true);

                _this4.set("_sLinkedInfoHeaderPopup", _this4.get("_objCandidateProfileStringsService").getString("linkedInfo") + ": " + objCandidate.full_name);

                _this4.get("_objRestAgencyCandidateProfileAgencyInfo").getCandidateAgencyLinkedInfo(_this4, "_objAgencyCandidateProfileAgencyInfoPromise", objCandidate.candidate_id, function (objData) {
                    _this4.set("arrLinkedData", [{
                        arrItems: [{
                            sLabel: _this4.get("_objCandidateProfileStringsService").getString("employmentReferenceNumber"),
                            sValue: objData.sEmploymentReferenceNumber,
                            bText: true
                        }, {
                            sLabel: _this4.get("_objCandidateProfileStringsService").getString("emailOptOut"),
                            sValue: objData.bEmailOptOut ? _this4.get("_objStringsService").getString("yes") : _this4.get("_objStringsService").getString("no"),
                            bText: true
                        }]
                    }]);
                    _this4.set("_bLinkedInfoPopupLoading", false);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () {
                    // This is the default values that should display for linked info for a candidate
                    // We can decide what we want to do incase it fails. This is just for display purpose now
                    _this4.set("arrLinkedData", [{
                        arrItems: [{
                            sLabel: _this4.get("_objCandidateProfileStringsService").getString("employmentReferenceNumber"),
                            sValue: null,
                            bText: true
                        }, {
                            sLabel: _this4.get("_objCandidateProfileStringsService").getString("emailOptOut"),
                            sValue: _this4.get("_objStringsService").getString("no"),
                            bText: true
                        }]
                    }]);
                    _this4.set("_bLinkedInfoPopupLoading", false);
                }));
            });
        },
        doCandidateView: function doCandidateView(sCandidateId) {
            var _this5 = this;

            Ember.run.next(function () {
                _this5.get("_objNavigation").navigateWithinContext("agency-candidate-search-view", {
                    "agency-candidate-search-view": [sCandidateId]
                });
            });
        },
        doCandidateCalendarView: function doCandidateCalendarView(sCandidateId) {
            var _this6 = this;

            Ember.run.next(function () {
                _this6.get("_objNavigation").navigateWithinContext("agency-candidate-search-view", {
                    "agency-candidate-search-view": [sCandidateId, "calendar"]
                });
            });
        },
        viewAllCandidateCalendarsInNewWindows: function viewAllCandidateCalendarsInNewWindows(arrSelectedItems) {
            var _this7 = this;

            $.each(arrSelectedItems, function (iKey, objCandidate) {
                _this7.viewCandidateCalendarInNewWindow(objCandidate.candidate_id);
            });
        },
        viewAllCandidatesInNewWindows: function viewAllCandidatesInNewWindows(arrSelectedItems) {
            var _this8 = this;

            $.each(arrSelectedItems, function (iKey, objCandidate) {
                _this8.viewCandidateInNewWindow(objCandidate.candidate_id);
            });
        },
        viewCandidateInNewWindow: function viewCandidateInNewWindow(sCandidateId) {
            var sLink = this.get("_objNavigation").navigateWithinContext("agency-candidate-search-view", {
                "agency-candidate-search-view": [sCandidateId]
            }, null, true);
            // Open the candidate link
            this.openLink(sLink);
        },
        viewCandidateCalendarInNewWindow: function viewCandidateCalendarInNewWindow(sCandidateId) {
            var sLink = this.get("_objNavigation").navigateWithinContext("agency-candidate-search-view", {
                "agency-candidate-search-view": [sCandidateId, "calendar"]
            }, null, true);
            // Open the candidate link
            this.openLink(sLink);
        },
        openLink: function openLink(sLink) {
            window.open(sLink);
        },

        addCandidateFabButton: function addCandidateFabButton() {
            // open add contact popup
            this.set("_bShowCandidateAddPopup", true);
        },
        _popupLinkedInfoClose: function _popupLinkedInfoClose() {
            this.set("_bLinkedInfoPopupLoading", false);
            this.set("_bShowLinkedInfoPopup", false);
        },
        _showPossibleShiftsPopup: function _showPossibleShiftsPopup() {
            this.set("_sPossibleShiftNotifErrorMessage", "");
            this.set("_bPossibleShiftsLoading", false);
            this.set("_bShowPossibleShiftsPopup", true);
        },
        _closePossibleShiftsPopup: function _closePossibleShiftsPopup() {
            if (!this.get("_bPossibleShiftsLoading")) {
                this.set("_bShowPossibleShiftsPopup", false);
            }
        },
        _sendBulkNotification: function _sendBulkNotification() {
            var _this9 = this;

            this.set("_bPossibleShiftsLoading", true);

            this.get("_objRestBulkNotificationsService").sendBulkNotification(this, "_objRestBulkNotificationsPromise", {
                type: "possible_shifts",
                data: {
                    order_id: this.get("_objNavigation.objRouteQueryParams.pos_shift_order_id"),
                    shift_ids: this.get("_arrPossibleShifts").getEach("shift_id")
                },
                candidate_ids: this.get("_arrSelectedItems").getEach("candidate_id")
            }, function () {
                _this9.set("_bShowPossibleShiftsPopup", false);
                _this9.set("_bShowSentMessagePopup", true);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                _this9.set("_bPossibleShiftsLoading", false);

                if (sCode === "INVALID_SHIFT_ID") {
                    _this9.set("_sPossibleShiftNotifErrorMessage", _this9.get("_objSSAgencyStringsService").getString("possibleShiftsNotificationShiftError"));
                } else if (sCode === "403" || sCode === "401") {
                    _this9.get("_objSnackbarService").showSnackbarMessage(_this9.get("_objEmberLibString").getString("requiredPermission"));
                } else {
                    _this9.get("_objSnackbarService").showSnackbarMessage(_this9.get("_objStringsService").getString("serverError"));
                }
            }));
        },
        actions: {
            onInputLoaded: function onInputLoaded() {
                var _this10 = this;

                this.get("_objFabButtonService").showFabButton(function () {
                    _this10.addCandidateFabButton();
                });
            },
            onCandidateAddPopupClose: function onCandidateAddPopupClose() {
                this.set("_bShowCandidateAddPopup", false);
            },
            onNavigateToCandidateAction: function onNavigateToCandidateAction(sCandidateId) {
                var _this11 = this;

                this.set("_bShowCandidateAddPopup", false);
                Ember.run.later(function () {
                    _this11.doCandidateView(sCandidateId);
                }, 600);
            },
            onCandidateAdded: function onCandidateAdded(objResponse) {
                var _this12 = this;

                this.set("_bShowCandidateAddPopup", false);
                // This is in a run later because of the redirect issue of how the popup closes. This happens because
                // we do a navigate to for the candidate view. Then the url trickery code fire that will do a navigate
                // back to close the popup and then it will navigate back to the listing page. Thus we add this run
                // later because we want the url trickery code to execute first that will close the popup first.
                // Then we do the navigate to the candidate view page
                // Or go read this issue https://github.com/A24Group/dunkin/issues/94
                Ember.run.later(function () {
                    _this12.doCandidateView(objResponse.id);
                }, 600);
            },
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this.onItemsSelect(null);
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrSelectedItems) {
                this.onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected(arrSelectedItems) {
                this.onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableCell: function cardDatagridTableCell(sColumnName, objRowData) {
                // If any of the name columns are clicked
                if (sColumnName === "first_name" || sColumnName === "last_name" || sColumnName === "full_name") {

                    var sCandidateId = objRowData.candidate_id;

                    this.doCandidateView(sCandidateId);
                }
            },
            onLinkedInfoPopupClose: function onLinkedInfoPopupClose() {
                this._popupLinkedInfoClose();
            },
            onPossibleShiftPopupClose: function onPossibleShiftPopupClose() {
                this._closePossibleShiftsPopup();
            },
            onPossibleShiftPopupSend: function onPossibleShiftPopupSend() {
                this._sendBulkNotification();
            },
            onPageReload: function onPageReload() {
                this.set("_bShowLoadFailedPopup", false);
                this.doServiceCalls();
            },
            onPossibleNonShiftPopupClose: function onPossibleNonShiftPopupClose() {
                this.set("_bShowPossibleNonShiftsPopup", false);
            },
            onShowSentMessage: function onShowSentMessage() {
                this.set("_bShowSentMessagePopup", true);
            }
        }
    });
});