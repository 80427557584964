define("a24-staffshift-subdomain-agency/components/page-agency-linked-client-search", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _didRenderChangesMixin, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        objQueryParams: null,
        bDatagridLoading: false,
        _bPageLoading: true,
        _objGetSiteSuggestions: null,
        _objGetComplianceContract: null,
        _objGetOrgSuggestions: null,
        _objDatagridConfig: null,
        _arrSelectedItems: null,
        _objSelectedItem: null,

        // strings, enums, constants
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSSAgencyStrings: Ember.inject.service("a24-strings-staffshift-agency"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objSSCoreEnum: Ember.inject.service("a24-enums-staffshift-core"),
        _objSSAgencyEnums: Ember.inject.service("a24-enums-staffshift-agency"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),

        // services
        _objUserSession: Ember.inject.service("user-session"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigation: Ember.inject.service("navigation"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objAgencySiteService: Ember.inject.service("rest-agency-site"),
        _objAgencyWardService: Ember.inject.service("rest-agency-site-ward"),
        _objAgencyOrgService: Ember.inject.service("rest-agency-organisation"),
        _objSiteTypesService: Ember.inject.service("rest-site-type"),
        _objWardTypesService: Ember.inject.service("rest-ward-type"),
        _objComplianceContractService: Ember.inject.service("rest-agency-compliance-contract"),
        _objFilterCustomCandidateDistanceService: Ember.inject.service("card-datagrid-custom-filter-candidate-distance"),
        _objFilterCustomWardService: Ember.inject.service("card-datagrid-custom-filter-ward"),
        _objAgencySavedSearchService: Ember.inject.service("rest-agency-saved-searches"),
        _objSiteTypesResponse: null,
        _objWardTypesResponse: null,

        // react component
        _objChatterIframeComService: Ember.inject.service("chatter-iframe-com"),
        _sIFrameUrl: null,
        _bReactPopupLoading: true,
        _bReactPopupOpen: false,
        _objReactPopupPostData: null,

        init: function init() {
            this._super.apply(this, arguments);

            this.get("_objTitleBarService").setTitleBar(this.get("_objSSAgencyStrings").getString("clientSearch"));

            // Build up the url with context id.
            this.set("_sUrlBase", _environment.default.sRestApiBaseUrl + "/v1/agency/" + this.get("_objNavigation.sContextId") + "/advanced-search/agency-client");

            this.set("_sIFrameUrl", _environment.default.sReactGatewayUrl);

            this.doServiceCalls();
        },

        doServiceCalls: function doServiceCalls() {
            var _this = this;

            this.set("_bPageLoading", true);
            var objQuery = {
                sortBy: { mValue: "sSiteTypeName" },
                page: { mValue: 1 },
                items_per_page: { mValue: 1000 }
            };

            var objPromiseSiteTypes = this.get("_objSiteTypesService").getSiteTypes(this, "_objSiteTypesResponse", objQuery, function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this._handleServerErrors();
            }));

            objQuery.sortBy = { mValue: "sWardTypeName" };

            var objPromiseWardTypes = this.get("_objWardTypesService").getWardTypes(this, "_objWardTypesResponse", objQuery, function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this._handleServerErrors();
            }));

            var objPromiseList = {
                _objRSVPSiteTypes: objPromiseSiteTypes,
                _objRSVPWardTypes: objPromiseWardTypes
            };

            var arrRequiredResponses = ["_objRSVPSiteTypes", "_objRSVPWardTypes"];

            var objThis = this;

            a24RSVP.configure(this, objPromiseList, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {

                    var arrSiteTypes = [];
                    if (!a24Core.isEmpty(objResponses._objRSVPSiteTypes)) {
                        arrSiteTypes = objResponses._objRSVPSiteTypes.objResponse;
                    }

                    var arrWardTypes = [];
                    if (!a24Core.isEmpty(objResponses._objRSVPWardTypes)) {
                        arrWardTypes = objResponses._objRSVPWardTypes.objResponse;
                    }

                    objThis._setupPageConfig(arrSiteTypes, arrWardTypes);
                }
            }]);
        },

        _setupPageConfig: function _setupPageConfig(arrSiteTypes, arrWardTypes) {
            var _this2 = this;

            var arrNodeLevels = this.get("_objSSAgencyEnums").getList("nodeLevel");
            var arrNodeLevelsDropdown = [];
            for (var k = 0; k < arrNodeLevels.length; k++) {
                arrNodeLevelsDropdown.push({
                    sTitle: arrNodeLevels[k].sLabel,
                    sValue: arrNodeLevels[k].sValue
                });
            }

            var arrComplianceContractStatus = this.get("_objSSAgencyEnums").getList("complianceContractStatus");
            var arrComplianceContractStatusDropdown = [];
            for (var y = 0; y < arrComplianceContractStatus.length; y++) {
                arrComplianceContractStatusDropdown.push({
                    sTitle: arrComplianceContractStatus[y].sLabel,
                    sValue: arrComplianceContractStatus[y].sValue
                });
            }

            var arrSiteTypesDropdown = [];
            var arrSiteTypesIds = [];
            for (var i = 0; i < arrSiteTypes.length; i++) {
                arrSiteTypesIds.push(arrSiteTypes[i]._id);
                arrSiteTypesDropdown.push({
                    sTitle: arrSiteTypes[i].name,
                    sValue: arrSiteTypes[i].name
                });
            }

            var arrWardTypesDropdown = [];
            var arrWardTypesIds = [];
            for (var j = 0; j < arrWardTypes.length; j++) {
                arrWardTypesIds.push(arrWardTypes[j]._id);
                arrWardTypesDropdown.push({
                    sTitle: arrWardTypes[j].name,
                    sValue: arrWardTypes[j].name
                });
            }

            var sCountyProvince = null;
            if (this.get("_objUserSession.objContextDetails.sCountryId") === "710") {
                sCountyProvince = this.get("_objStringsService").getString("province");
            } else {
                sCountyProvince = this.get("_objCandidateProfileStringsService").getString("county");
            }

            // This array contain the datagrid columns
            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objSSAgencyStrings").getString("organisationName"),
                sProperty: "organisation_name",
                sFilterType: "text",
                bStopFiltering: true,
                bText: true,
                bIsClickable: false,
                iWidth: 20
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objSSAgencyStrings").getString("organisationName"),
                sProperty: "organisation_id",
                sFilterType: "suggest",
                bCustom: true,
                iWidth: 10,
                bSilent: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "sOrganisationId",
                sFilterOptionsLabelKey: "sOrganisationName",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: true,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    if (!a24Core.isEmpty(objRowData.organisation_name)) {
                        return Ember.String.htmlSafe("<span title=\"" + objRowData.organisation_name + "\">" + objRowData.organisation_name + "</span>");
                    }
                    return "";
                },
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var objQuery = {
                        sOrganisationName: {
                            mValue: sSuggestVal,
                            bLike: true,
                            bEncode: true
                        },
                        sortBy: {
                            mValue: "sOrganisationName"
                        },
                        items_per_page: {
                            mValue: 10
                        }
                    };

                    _this2.get("_objAgencyOrgService").getAgencyOrganisations(_this2, "_objGetOrgSuggestions", objQuery, function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrData);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }));
                }
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objSSAgencyStrings").getString("siteName"),
                sProperty: "site_name",
                sFilterType: "text",
                bStopFiltering: true,
                bText: true,
                bIsClickable: false,
                iWidth: 20
            }, {
                iUniqueColNum: 4,
                sTitle: this.get("_objSSAgencyStrings").getString("siteName"),
                sProperty: "site_id",
                sFilterType: "suggest",
                bCustom: true,
                iWidth: 10,
                bSilent: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "sSiteId",
                sFilterOptionsLabelKey: "sSiteName",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: true,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    if (!a24Core.isEmpty(objRowData.site_name)) {
                        return Ember.String.htmlSafe("<span title=\"" + objRowData.site_name + "\">" + objRowData.site_name + "</span>");
                    }
                    return "";
                },
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var objQuery = {
                        sSiteName: {
                            mValue: sSuggestVal,
                            bLike: true,
                            bEncode: true
                        },
                        bAgencyLinked: {
                            mValue: true
                        },
                        sortBy: {
                            mValue: "sSiteName"
                        },
                        items_per_page: {
                            mValue: 10
                        }
                    };

                    _this2.get("_objAgencySiteService").getLinkedClients(_this2, "_objGetSiteSuggestions", objQuery, function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        var arrFormatted = [];
                        for (var _i = 0; _i < arrData.length; _i++) {
                            var objDataItem = arrData[_i];
                            var sLabel = objDataItem.sOrganisationName + ": " + objDataItem.sSiteName;
                            objDataItem.sSiteName = sLabel;

                            arrFormatted.push(objDataItem);
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrFormatted);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }));
                }
            }, {
                iUniqueColNum: 5,
                sTitle: this.get("_objSSAgencyStrings").getString("wardName"),
                sProperty: "ward_name",
                sFilterType: "text",
                bText: true,
                bStopFiltering: true,
                bIsClickable: false,
                iWidth: 20
            }, {
                iUniqueColNum: 18,
                sTitle: this.get("_objSSAgencyStrings").getString("wardName"),
                sProperty: "ward_id",
                sFilterType: "custom",
                bText: true,
                bSilent: true,
                bFilterOr: true,
                bHideEmptyCheck: true,
                objFilterSiteService: this.get("_objAgencySiteService"),
                objFilterWardService: this.get("_objAgencyWardService"),
                sFilterCustomComponentName: "card-datagrid-custom-filter-ward",
                objFilterCustomService: this.get("_objFilterCustomWardService")
            }, {
                iUniqueColNum: 6,
                sTitle: this.get("_objSSAgencyStrings").getString("nodeLevel"),
                sProperty: "node_level",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: arrNodeLevelsDropdown,
                iWidth: 15
            }, {
                iUniqueColNum: 7,
                sTitle: this.get("_objSSAgencyStrings").getString("clientReference"),
                sProperty: "client_reference",
                sFilterType: "text",
                bText: true,
                bStopFiltering: true,
                bIsClickable: false,
                iWidth: 20
            }, {
                iUniqueColNum: 8,
                sTitle: this.get("_objSSAgencyStrings").getString("siteType"),
                sProperty: "site_type_name",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: arrSiteTypesDropdown,
                iWidth: 15
            }, {
                iUniqueColNum: 9,
                sTitle: this.get("_objSSAgencyStrings").getString("wardType"),
                sProperty: "ward_type_name",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: arrWardTypesDropdown,
                iWidth: 15
            }, {
                iUniqueColNum: 10,
                sTitle: this.get("_objStringsService").getString("addressLine1"),
                sProperty: "site_address_line_1",
                sFilterType: "text",
                bStopFiltering: true,
                bText: true,
                bIsClickable: false,
                iWidth: 20
            }, {
                iUniqueColNum: 11,
                sTitle: this.get("_objStringsService").getString("addressLine2"),
                sProperty: "site_address_line_2",
                sFilterType: "text",
                bStopFiltering: true,
                bText: true,
                bIsClickable: false,
                iWidth: 20
            }, {
                iUniqueColNum: 12,
                sTitle: sCountyProvince,
                sProperty: "site_province",
                sFilterType: "text",
                bFilterOr: true,
                bFilterAnd: true,
                bText: true,
                bIsClickable: false,
                iWidth: 20
            }, {
                iUniqueColNum: 13,
                sTitle: this.get("_objStringsService").getString("cityTown"),
                sProperty: "site_city",
                sFilterType: "text",
                bFilterOr: true,
                bFilterAnd: true,
                bText: true,
                bIsClickable: false,
                iWidth: 15
            }, {
                iUniqueColNum: 14,
                sTitle: this.get("_objSSAgencyStrings").getString("postCode"),
                sProperty: "site_post_code",
                sFilterType: "text",
                bFilterOr: true,
                bFilterAnd: true,
                bText: true,
                bIsClickable: false,
                iWidth: 15
            }, {
                iUniqueColNum: 15,
                sTitle: this.get("_objSSAgencyStrings").getString("siteReferenceNumber"),
                sProperty: "site_reference_number",
                sFilterType: "text",
                bText: true,
                bIsClickable: false,
                iWidth: 15
            }, {
                iUniqueColNum: 16,
                sTitle: this.get("_objSSAgencyStrings").getString("wardReferenceNumber"),
                sProperty: "ward_reference_number",
                sFilterType: "text",
                bText: true,
                bIsClickable: false,
                iWidth: 15
            }, {
                iUniqueColNum: 17,
                sTitle: this.get("_objCandidateProfileStringsService").getString("distance"),
                sProperty: "site_physical_geo_location",
                bText: true,
                sFilterType: "custom",
                bSilent: true,
                iWidth: 20,
                bHideEmptyCheck: true,
                bClientSearch: true,
                sContext: this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY"),
                sFilterCustomComponentName: "card-datagrid-custom-filter-candidate-distance",
                objFilterCustomService: this.get("_objFilterCustomCandidateDistanceService")
            }, {
                iUniqueColNum: 18,
                sTitle: this.get("_objSSAgencyStrings").getString("contractName"),
                sProperty: "compliance_contract_id",
                sFilterType: "suggest",
                bCustom: true,
                iWidth: 10,
                bSilent: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "_id",
                sFilterOptionsLabelKey: "name",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: true,
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var objQuery = {
                        name: {
                            mValue: sSuggestVal,
                            bLike: true,
                            bEncode: true
                        },
                        sortBy: {
                            mValue: "name"
                        },
                        items_per_page: {
                            mValue: 10
                        }
                    };

                    _this2.get("_objComplianceContractService").getComplianceContracts(_this2, "_objGetComplianceContract", objQuery, function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrData);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }));
                }
            }, {
                iUniqueColNum: 19,
                sTitle: this.get("_objSSAgencyStrings").getString("contractName"),
                sProperty: "compliance_contract_name",
                sFilterType: "text",
                bStopFiltering: true,
                bText: true,
                bIsClickable: false,
                iWidth: 20
            }, {
                iUniqueColNum: 20,
                sTitle: this.get("_objSSAgencyStrings").getString("contractStatus"),
                sProperty: "compliance_contract_status",
                sFilterType: "dropdown",
                bMapping: true,
                arrMappingData: arrComplianceContractStatusDropdown,
                iWidth: 15
            }];

            var arrSingleActions = [{
                sTitle: this.get("_objSSAgencyStrings").getString("assignComplianceContract"),
                sIcon: "assignment_turned_in",
                executeCardAction: function executeCardAction() {
                    var sClientName = _this2.get("_objSelectedItem.site_name");
                    if (!a24Core.isEmpty(_this2.get("_objSelectedItem.ward_name"))) {
                        sClientName += " - " + _this2.get("_objSelectedItem.ward_name");
                    }
                    _this2.set("_bReactPopupOpen", true);
                    _this2.set("_objReactPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "agency/linkedContracts/assignPopup",
                            data: {
                                client_name: sClientName,
                                organisation_id: _this2.get("_objSelectedItem.organisation_id"),
                                site_id: _this2.get("_objSelectedItem.site_id"),
                                ward_id: _this2.get("_objSelectedItem.ward_id")
                            }
                        }
                    });
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return (_this2.get("_objSSAgencyEnums.nodeLevel.SITE.backend") === arrSelectedItems[0].node_level || _this2.get("_objSSAgencyEnums.nodeLevel.WARD.backend") === arrSelectedItems[0].node_level) && a24Core.isEmpty(arrSelectedItems[0].compliance_contract_link_id);
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("removeComplianceContract"),
                sIcon: "assignment_late",
                executeCardAction: function executeCardAction() {
                    var sClientName = _this2.get("_objSelectedItem.site_name");
                    if (!a24Core.isEmpty(_this2.get("_objSelectedItem.ward_name"))) {
                        sClientName += " - " + _this2.get("_objSelectedItem.ward_name");
                    }
                    _this2.set("_bReactPopupOpen", true);
                    _this2.set("_objReactPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "agency/linkedContracts/deassignPopup",
                            data: {
                                client_name: sClientName,
                                contract_name: _this2.get("_objSelectedItem.compliance_contract_name"),
                                organisation_id: _this2.get("_objSelectedItem.organisation_id"),
                                contract_link_id: _this2.get("_objSelectedItem.compliance_contract_link_id")
                            }
                        }
                    });
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    return (_this2.get("_objSSAgencyEnums.nodeLevel.SITE.backend") === arrSelectedItems[0].node_level || _this2.get("_objSSAgencyEnums.nodeLevel.WARD.backend") === arrSelectedItems[0].node_level) && !a24Core.isEmpty(arrSelectedItems[0].compliance_contract_link_id);
                }
            }];

            this.set("_objDatagridConfig", {
                sDatagridHeader: this.get("_objSSAgencyStrings").getString("clientSearch"),
                iMaxHeight: 40 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bEmitDidDoServiceCall: false,
                bHasReloadButton: true,
                arrSingleItemSelectActions: arrSingleActions,
                arrMultiItemSelectActions: [],
                objSavedSearchService: this.get("_objAgencySavedSearchService"),
                sSavedSearchRef: "agency-search-clients",
                arrHeaderConfig: arrDataGridHeaderConfig,
                objPermanentQuery: {
                    linked: {
                        mValue: true
                    }
                }
            });

            this.set("_bPageLoading", false);
        },
        _addRemoveSuccess: function _addRemoveSuccess() {
            var _this3 = this;

            this.set("_bReactPopupOpen", false);
            this.set("_bPageLoading", true);

            Ember.run.next(function () {
                Ember.run.schedule("afterRender", function () {
                    _this3.set("_bPageLoading", false);
                });
            });
        },

        _onItemsSelect: function _onItemsSelect(arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedItem", arrSelectedItems[0]);
            } else {
                this.set("_objSelectedItem", null);
            }
            // Set the selected items
            this.set("_arrSelectedItems", arrSelectedItems);
        },
        actions: {
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this._onItemsSelect(null);
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },

            onRecieveData: function onRecieveData(objData) {
                if (objData.type === "app_ready") {
                    this.set("_bReactPopupLoading", false);
                } else if (objData.type === "refresh") {
                    this._addRemoveSuccess();
                } else if (objData.type === "close") {
                    this.set("_bReactPopupOpen", false);
                }
            }
        }
    });
});