define("a24-staffshift-subdomain-agency/components/page-agency-candidate-view", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/templates/components/page-agency-candidate-view", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _pageAgencyCandidateView, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _pageAgencyCandidateView.default,
        _sCandidateId: null,
        _sCandidateName: null,
        _arrReloadActions: null,
        _bServerError: false,
        _bDisplayReloadCard: false,
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objCandidateTypeTabsService: Ember.inject.service("candidate-type-tabs"),
        _objSystemService: Ember.inject.service("rest-system"),
        _objSettingsRestService: Ember.inject.service("rest-agency-candidate-settings"),
        _objStaffshiftCoreEnums: Ember.inject.service("a24-enums-staffshift-core"),
        _objStringsCandidateProfile: Ember.inject.service("a24-strings-candidate-profile"),
        _objSSAgencyStringsService: Ember.inject.service("a24-strings-staffshift-agency"),
        _objStringsCandidateAvailability: Ember.inject.service("a24-strings-staffshift-availability"),
        _objStringsSSCore: Ember.inject.service("a24-strings-staffshift-core"),
        _objRestAgencyGroupCandidateInvite: Ember.inject.service("rest-invitation-verification"),
        _objRestAgencyNotificationTypes: Ember.inject.service("rest-agency-notification-types"),
        _objRestAgencyCandidateNotification: Ember.inject.service("rest-agency-candidate-notifications"),
        _objRestAgencyNotificationTypeTemplates: Ember.inject.service("rest-agency-notification-type-templates"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objUserSession: Ember.inject.service("user-session"),

        _iLastSkillTabIndex: null,
        _iLastQualificationTabIndex: null,
        _iLastProfessionalRegistrationTabIndex: null,
        _objResendInviteServiceCall: null,
        _bInvitePopupLoading: false,
        _bShowResendInvitePopup: false,
        _arrPositionTypes: null,
        _bLoading: true,
        _bHasSkills: false,
        _bHasQualifications: false,
        _bHasProfessionalRegistrations: false,
        _sCurrentRouteName: "",

        _objSpecialNotificationTypePreviewPopupAction: null,
        _bShowResendInviteSuccessPopup: false,
        _sResendInviteSuccessMessage: null,
        _bShowSendNotificationPopup: false,
        _bDisplayPreviewSend: false,
        _arrNotificationTypeDropdown: null,
        _bSendNotificationPopupLoading: false,
        _bShowSentMessagePopup: false,
        _bShowNotificationPreviewPopup: false,
        _bShowNotificationTypes: false,
        _bNotificationTypeLoading: false,
        _arrNotificationCache: null,
        _bShiftNotification: false,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            var objCandidateViewParams = this.get("_objNavigationService.objRouteParams")["index.agency-candidate-profile"];

            var objCandidateSearchViewParams = this.get("_objNavigationService.objRouteParams")["index.agency-candidate-search-view"];

            if (!a24Core.isEmpty(objCandidateViewParams)) {
                this.set("_sCandidateId", objCandidateViewParams.candidate_id);
            }
            if (!a24Core.isEmpty(objCandidateSearchViewParams)) {
                this.set("_sCandidateId", objCandidateSearchViewParams.candidate_id);
            }
            this.set("_arrReloadActions", [{
                sTitle: this.get("_objStringsCandidateProfile").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.getPositionTypes();
                }
            }]);

            this.set("_objSpecialNotificationPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.onPopupNotificationCancel();
                }
            });

            this.set("_objSpecialNotificationTypePreviewPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.set("_bShowNotificationPreviewPopup", false);
                }
            });

            this._setCurrentRouteName();
            this.getPositionTypes();

            var objThis = this;
            var objActionItems = {
                arrActionItems: []
            };
            objActionItems.arrActionItems.pushObject({
                sTitle: this.get("_objStringsCandidateProfile").getString("sendNotification"),
                sIcon: "notifications",
                executeCardAction: function executeCardAction() {
                    objThis.onNoticationEmailClick();
                }
            });

            this.get("_objTitleBarService").setTitleBar(this.get("_objStringsCandidateProfile").getString("candidateProfile"), function () {
                _this.get("_objNavigationService").navigateBack();
            }, false, "card-actions-component", objActionItems);
            this.set("_objTitleBarService.objActiveSkillTab", null);
            this.set("_objTitleBarService.objActiveQualificationTab", null);
            this.set("_objTitleBarService.objActiveProfessionalRegistrationTab", null);

            var objValidationConfig = {};

            objValidationConfig["objChildren.for"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.in"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.shiftPattern"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.replyWith"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };

            this.set("objChildConfig", objValidationConfig);

            this.set("_arrNotificationCache", {});
        },
        getPositionTypes: function getPositionTypes() {
            Ember.run.once(this, this.getPositionTypesOnce);
        },
        getPositionTypesOnce: function getPositionTypesOnce() {
            var _this2 = this;

            var arrAgencyGroups = this.get("_objSettingsRestService.objSettings.agency_groups");
            var bDoServiceCall = a24Core.isEmpty(this.get("_arrPositionTypes"));
            this.set("_bServerError", false);

            if (!a24Core.isEmpty(arrAgencyGroups)) {
                var arrCandidateTypes = [];

                for (var i = 0; i < arrAgencyGroups.length; i++) {
                    if (!a24Core.isEmpty(arrAgencyGroups[i].candidate_type)) {
                        arrCandidateTypes.push(arrAgencyGroups[i].candidate_type);
                    }
                }
                if (a24Core.isEmpty(arrCandidateTypes)) {
                    bDoServiceCall = false;
                }
            } else {
                bDoServiceCall = false;
            }

            if (!bDoServiceCall) {
                this.setTabItems();
                this.set("_bLoading", false);
                return;
            }

            this.get("_objSystemService").getPositionTypes(this, "_objSystemServicePromise", function (arrResponse) {
                _this2.set("_arrPositionTypes", arrResponse);
                var sCandidateProfile = _this2.get("_objStringsCandidateProfile").getString("candidateProfile");
                _this2.setTitleBarTextWithSetup(sCandidateProfile);
                _this2.setTabItems();
                _this2.set("_bLoading", false);
                _this2.set("_bServerError", false);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                _this2.set("_bServerError", true);
            }), {
                sortBy: {
                    mValue: "name"
                },
                items_per_page: {
                    mValue: 1000
                }
            });
        },
        setTabItems: function setTabItems() {
            this.set("_objTitleBarService.objActiveSkillTab", null);
            this.set("_objTitleBarService.objActiveQualificationTab", null);
            var arrTabs = [{
                iIndex: 0,
                bActive: this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.index",
                sText: this.get("_objStringsCandidateProfile").getString("general"),
                sLink: this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, true)
            }, {
                iIndex: 1,
                bActive: this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.other-names",
                sText: this.get("_objStringsCandidateProfile").getString("otherNames"),
                sLink: this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.other-names", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, true)
            }, {
                iIndex: 2,
                bActive: this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.misc-details",
                sText: this.get("_objStringsCandidateProfile").getString("miscDetails"),
                sLink: this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.misc-details", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, true)
            }, {
                iIndex: 3,
                bActive: this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.equality",
                sText: this.get("_objStringsCandidateProfile").getString("equalityMonitoring"),
                sLink: this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.equality", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, true)
            }, {
                iIndex: 4,
                bActive: this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.next-of-kin",
                sText: this.get("_objStringsCandidateProfile").getString("nextOfKin"),
                sLink: this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.next-of-kin", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, true)
            }, {
                iIndex: 5,
                bActive: this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.languages",
                sText: this.get("_objStringsCandidateProfile").getString("languages"),
                sLink: this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.languages", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, true)
            }, {
                iIndex: 6,
                bActive: this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.documents",
                sText: this.get("_objStringsService").getString("documents"),
                sLink: this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.documents", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, true)
            }, {
                iIndex: 7,
                bActive: this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.work-history",
                sText: this.get("_objStringsCandidateProfile").getString("workHistory"),
                sLink: this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.work-history", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, true)
            }, {
                iIndex: 8,
                bActive: this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.dismissals",
                sText: this.get("_objStringsCandidateProfile").getString("dismissal"),
                sLink: this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.dismissals", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, true)
            }, {
                iIndex: 9,
                bActive: this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.position-held",
                sText: this.get("_objStringsCandidateProfile").getString("positionHeld"),
                sLink: this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.position-held", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, true)
            }, {
                iIndex: 10,
                bActive: this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.social-links",
                sText: this.get("_objStringsCandidateProfile").getString("socialLinks"),
                sLink: this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.social-links", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, true)
            }];

            var bSetTabs = true;

            bSetTabs = this.setSkillTabs(arrTabs);

            if (bSetTabs) {
                bSetTabs = this.setQualificationTabs(arrTabs);
            }

            if (bSetTabs) {
                bSetTabs = this.setProfessionalRegistrationTabs(arrTabs);
            }

            if (bSetTabs) {
                arrTabs.push({
                    iIndex: arrTabs.length,
                    bActive: this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.references",
                    sText: this.get("_objStringsCandidateProfile").getString("references"),
                    sLink: this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.references", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")]
                    }, null, true)
                });

                arrTabs.push({
                    iIndex: arrTabs.length,
                    bActive: this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.tags",
                    sText: this.get("_objStringsCandidateProfile").getString("tags"),
                    sLink: this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.tags", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")]
                    }, null, true)
                });

                //iIndex can still be arrTabs.length since last item got pushed to
                //arrTabs thus bumping the length with one

                arrTabs.push({
                    iIndex: arrTabs.length,
                    bActive: this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.calendar",
                    sText: this.get("_objStringsCandidateAvailability").getString("calendar"),
                    sLink: this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.calendar", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")]
                    }, null, true)
                });

                arrTabs.push({
                    iIndex: arrTabs.length,
                    bActive: this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.notifications-preferences",
                    sText: this.get("_objStringsCandidateProfile").getString("notificationsPreferences"),
                    sLink: this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.notifications-preferences", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")]
                    }, null, true)
                });

                this.get("_objTitleBarService").setTabItems(arrTabs, Ember.run.bind(this, this.funcTitleBarCallback));
            }
        },
        setSkillTabs: function setSkillTabs(arrTabs) {
            var arrAgencyGroups = this.get("_objSettingsRestService.objSettings.agency_groups");
            var iMaxTabs = arrTabs.length;
            var bRouteParamMatchType = false;

            if (!a24Core.isEmpty(arrAgencyGroups)) {
                var arrCandidateTypes = [];

                for (var i = 0; i < arrAgencyGroups.length; i++) {
                    if (!a24Core.isEmpty(arrAgencyGroups[i].candidate_type)) {
                        arrCandidateTypes.push(arrAgencyGroups[i].candidate_type);
                    }
                }
                var arrTabsNew = this.get("_objCandidateTypeTabsService").generateCandidateTypesTabs(arrCandidateTypes, this.get("_arrPositionTypes"));
                var sPreText = this.get("_objStringsCandidateProfile").getString("skills");
                var bMultiple = false;
                if (arrTabsNew.length > 1) {
                    bMultiple = true;
                    sPreText = this.get("_objStringsCandidateProfile").getTokenString("skillsHeader", "");
                }

                for (var l = 0; l < arrTabsNew.length; l++) {
                    arrTabsNew[l].sTypeText = arrTabsNew[l].sText;
                    if (bMultiple) {
                        arrTabsNew[l].sText = sPreText + arrTabsNew[l].sText;
                    } else {
                        arrTabsNew[l].sText = sPreText;
                    }

                    arrTabsNew[l].iIndex = iMaxTabs + l;
                    var bActive = false;
                    var sCandidateType = "";
                    if (!a24Core.isEmpty(this.get("_objNavigationService.objRouteParams")["index.agency-candidate-search-view.skills.skill"])) {
                        sCandidateType = this.get("_objNavigationService.objRouteParams")["index.agency-candidate-search-view.skills.skill"].candidate_type;
                        if (sCandidateType === arrTabsNew[l].sCode && this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.skills.skill") {
                            bActive = true;
                            bRouteParamMatchType = true;
                        }
                    }
                    if (bActive) {
                        this.set("_objTitleBarService.objActiveSkillTab", arrTabsNew[l]);
                    }
                    arrTabsNew[l].bActive = bActive;
                    arrTabsNew[l].sLink = this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.skills", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")],
                        "skills": [arrTabsNew[l].sCode]
                    }, null, true);
                    this.set("_bHasSkills", true);
                    arrTabs.push(arrTabsNew[l]);
                }
            }
            if (this.get("_bHasSkills")) {
                if (this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.skills.index") {
                    this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.skills", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")],
                        "skills": [arrTabs[iMaxTabs].sCode]
                    }, null, false, false);
                    //this will trigger a route change so re-setup will be handled by observers
                    return false;
                } else if (this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.skills.skill" && !bRouteParamMatchType) {

                    //If we are on the skill route and the route param did not match any valid candidate type then set
                    //the first item as selected and update the route param with the navigate.
                    arrTabs[iMaxTabs].bActive = true;
                    this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.skills", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")],
                        "skills": [arrTabs[iMaxTabs].sCode]
                    }, null, false, false);
                }
            } else {
                if (this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.skills.skill") {
                    this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.skills", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")]
                    }, null, false, false);
                    return false;
                }

                var _bActive = this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.skills.index";
                var objTabItem = {
                    iIndex: iMaxTabs,
                    bActive: _bActive,
                    sText: this.get("_objStringsCandidateProfile").getString("skills"),
                    sLink: this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.skills", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")]
                    }, null, true)
                };
                if (_bActive) {
                    this.set("_objTitleBarService.objActiveSkillTab", arrTabs[iMaxTabs]);
                }
                arrTabs.push(objTabItem);
            }

            this.set("_iLastSkillTabIndex", arrTabs.length - 1);

            return true;
        },
        setQualificationTabs: function setQualificationTabs(arrTabs) {
            var arrAgencyGroups = this.get("_objSettingsRestService.objSettings.agency_groups");
            var iMaxTabs = arrTabs.length;
            var bRouteParamMatchType = false;

            if (!a24Core.isEmpty(arrAgencyGroups)) {
                var arrCandidateTypes = [];

                for (var i = 0; i < arrAgencyGroups.length; i++) {
                    if (!a24Core.isEmpty(arrAgencyGroups[i].candidate_type)) {
                        arrCandidateTypes.push(arrAgencyGroups[i].candidate_type);
                    }
                }
                var arrTabsNew = this.get("_objCandidateTypeTabsService").generateCandidateTypesTabs(arrCandidateTypes, this.get("_arrPositionTypes"));
                var sPreText = this.get("_objStringsCandidateProfile").getString("qualifications");
                var bMultiple = false;
                if (arrTabsNew.length > 1) {
                    bMultiple = true;
                    sPreText = this.get("_objStringsCandidateProfile").getTokenString("qualificationsHeader", "");
                }

                for (var l = 0; l < arrTabsNew.length; l++) {
                    arrTabsNew[l].sTypeText = arrTabsNew[l].sText;
                    if (bMultiple) {
                        arrTabsNew[l].sText = sPreText + arrTabsNew[l].sText;
                    } else {
                        arrTabsNew[l].sText = sPreText;
                    }

                    arrTabsNew[l].iIndex = iMaxTabs + l;
                    var bActive = false;
                    var sCandidateType = "";
                    if (!a24Core.isEmpty(this.get("_objNavigationService.objRouteParams")["index.agency-candidate-search-view.qualifications.qualification"])) {
                        sCandidateType = this.get("_objNavigationService.objRouteParams")["index.agency-candidate-search-view.qualifications.qualification"].candidate_type;
                        if (sCandidateType === arrTabsNew[l].sCode && this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.qualifications.qualification") {
                            bActive = true;
                            bRouteParamMatchType = true;
                        }
                    }
                    if (bActive) {
                        this.set("_objTitleBarService.objActiveQualificationTab", arrTabsNew[l]);
                    }
                    arrTabsNew[l].bActive = bActive;
                    arrTabsNew[l].sLink = this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.qualifications", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")],
                        "qualifications": [arrTabsNew[l].sCode]
                    }, null, true);
                    this.set("_bHasQualifications", true);
                    arrTabs.push(arrTabsNew[l]);
                }
            }
            if (this.get("_bHasQualifications")) {
                if (this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.qualifications.index") {
                    this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.qualifications", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")],
                        "qualifications": [arrTabs[iMaxTabs].sCode]
                    }, null, false, false);
                    //this will trigger a route change so re-setup will be handled by observers
                    return false;
                } else if (this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.qualifications.qualification" && !bRouteParamMatchType) {

                    //If we are on the qualification route and the route param did not match any valid candidate type then set
                    //the first item as selected and update the route param with the navigate.
                    arrTabs[iMaxTabs].bActive = true;
                    this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.qualifications", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")],
                        "qualifications": [arrTabs[iMaxTabs].sCode]
                    }, null, false, false);
                }
            } else {
                if (this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.qualifications.qualification") {
                    this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.qualifications", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")]
                    }, null, false, false);
                    return false;
                }

                var _bActive2 = this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.qualifications.index";
                var objTabItem = {
                    iIndex: iMaxTabs,
                    bActive: _bActive2,
                    sText: this.get("_objStringsCandidateProfile").getString("qualifications"),
                    sLink: this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.qualifications", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")]
                    }, null, true)
                };
                if (_bActive2) {
                    this.set("_objTitleBarService.objActiveQualificationTab", arrTabs[iMaxTabs]);
                }
                arrTabs.push(objTabItem);
            }

            this.set("_iLastQualificationTabIndex", arrTabs.length - 1);

            return true;
        },
        setProfessionalRegistrationTabs: function setProfessionalRegistrationTabs(arrTabs) {
            var arrAgencyGroups = this.get("_objSettingsRestService.objSettings.agency_groups");
            var iMaxTabs = arrTabs.length;
            var bRouteParamMatchType = false;

            if (!a24Core.isEmpty(arrAgencyGroups)) {
                var arrCandidateTypes = [];

                for (var i = 0; i < arrAgencyGroups.length; i++) {
                    if (!a24Core.isEmpty(arrAgencyGroups[i].candidate_type)) {
                        arrCandidateTypes.push(arrAgencyGroups[i].candidate_type);
                    }
                }
                var arrTabsNew = this.get("_objCandidateTypeTabsService").generateCandidateTypesTabs(arrCandidateTypes, this.get("_arrPositionTypes"));
                var sPreText = this.get("_objStringsCandidateProfile").getString("professionalRegistration");
                var bMultiple = false;
                if (arrTabsNew.length > 1) {
                    bMultiple = true;
                    sPreText = this.get("_objStringsCandidateProfile").getTokenString("professionalRegistrationHeader", "");
                }

                for (var l = 0; l < arrTabsNew.length; l++) {
                    arrTabsNew[l].sTypeText = arrTabsNew[l].sText;
                    if (bMultiple) {
                        arrTabsNew[l].sText = sPreText + arrTabsNew[l].sText;
                    } else {
                        arrTabsNew[l].sText = sPreText;
                    }

                    arrTabsNew[l].iIndex = iMaxTabs + l;
                    var bActive = false;
                    var sCandidateType = "";
                    if (!a24Core.isEmpty(this.get("_objNavigationService.objRouteParams")["index.agency-candidate-search-view.professional-registrations.professional-registration"])) {
                        sCandidateType = this.get("_objNavigationService.objRouteParams")["index.agency-candidate-search-view.professional-registrations.professional-registration"].candidate_type;
                        if (sCandidateType === arrTabsNew[l].sCode && this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.professional-registrations.professional-registration") {
                            bActive = true;
                            bRouteParamMatchType = true;
                        }
                    }
                    if (bActive) {
                        this.set("_objTitleBarService.objActiveProfessionalRegistrationTab", arrTabsNew[l]);
                    }
                    arrTabsNew[l].bActive = bActive;
                    arrTabsNew[l].sLink = this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.professional-registrations", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")],
                        "professional-registrations": [arrTabsNew[l].sCode]
                    }, null, true);
                    this.set("_bHasProfessionalRegistrations", true);
                    arrTabs.push(arrTabsNew[l]);
                }
            }
            if (this.get("_bHasProfessionalRegistrations")) {
                if (this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.professional-registrations.index") {
                    this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.professional-registrations", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")],
                        "professional-registrations": [arrTabs[iMaxTabs].sCode]
                    }, null, false, false);
                    //this will trigger a route change so re-setup will be handled by observers
                    return false;
                } else if (this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.professional-registrations.professional-registration" && !bRouteParamMatchType) {

                    //If we are on the professional-registration route and the route param did not match any valid candidate type then set
                    //the first item as selected and update the route param with the navigate.
                    arrTabs[iMaxTabs].bActive = true;
                    this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.professional-registrations", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")],
                        "professional-registrations": [arrTabs[iMaxTabs].sCode]
                    }, null, false, false);
                }
            } else {
                if (this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.professional-registrations.professional-registration") {
                    this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.professional-registrations", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")]
                    }, null, false, false);
                    return false;
                }

                var _bActive3 = this.get("_sCurrentRouteName") === "index.agency-candidate-search-view.professional-registrations.index";
                var objTabItem = {
                    iIndex: iMaxTabs,
                    bActive: _bActive3,
                    sText: this.get("_objStringsCandidateProfile").getString("professionalRegistration"),
                    sLink: this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.professional-registrations", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")]
                    }, null, true)
                };
                if (_bActive3) {
                    this.set("_objTitleBarService.objActiveProfessionalRegistrationTab", arrTabs[iMaxTabs]);
                }
                arrTabs.push(objTabItem);
            }

            this.set("_iLastProfessionalRegistrationTabIndex", arrTabs.length - 1);

            return true;
        },
        setTitleBarTextWithSetup: function setTitleBarTextWithSetup(sTitleBar) {
            var _this3 = this;

            var objThis = this;
            var objActionItems = {
                arrActionItems: []
            };
            objActionItems.arrActionItems.pushObject({
                sTitle: this.get("_objStringsCandidateProfile").getString("sendNotification"),
                sIcon: "notifications",
                executeCardAction: function executeCardAction() {
                    objThis.onNoticationEmailClick();
                }
            });

            this.get("_objTitleBarService").setTitleBar(sTitleBar, function () {
                _this3.get("_objNavigationService").navigateBack();
            }, false, "card-actions-component", objActionItems);
        },
        _setCurrentRouteName: function _setCurrentRouteName() {
            var sCurrentRoute = "";
            var objRouteParams = this.get("_objNavigationService.objRouteParams");
            for (var sKey in objRouteParams) {
                if (objRouteParams.hasOwnProperty(sKey) && sKey.length > sCurrentRoute.length) {
                    sCurrentRoute = sKey;
                }
            }

            this.set("_sCurrentRouteName", sCurrentRoute);
        },
        onCurrentNameChange: Ember.observer("_objNavigationService.objRouteParams", function () {
            var _this4 = this;

            if (!a24Core.isEmpty(this.get("_objNavigationService.objRouteParams")["index.agency-candidate-search-view"])) {
                this._setCurrentRouteName();
                Ember.run.next(function () {
                    _this4.getPositionTypes();
                });
            }
        }),
        funcTitleBarCallback: function funcTitleBarCallback(objSelectedTab) {
            if (objSelectedTab.iIndex === 0) {
                this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, false, false);
            } else if (objSelectedTab.iIndex === 1) {
                this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.other-names", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, false, false);
            } else if (objSelectedTab.iIndex === 2) {
                this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.misc-details", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, false, false);
            } else if (objSelectedTab.iIndex === 3) {
                this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.equality", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, false, false);
            } else if (objSelectedTab.iIndex === 4) {
                this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.next-of-kin", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, false, false);
            } else if (objSelectedTab.iIndex === 5) {
                this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.languages", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, false, false);
            } else if (objSelectedTab.iIndex === 6) {
                this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.documents", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, false, false);
            } else if (objSelectedTab.iIndex === 7) {
                this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.work-history", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, false, false);
            } else if (objSelectedTab.iIndex === 8) {
                this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.dismissals", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, false, false);
            } else if (objSelectedTab.iIndex === 9) {
                this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.position-held", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, false, false);
            } else if (objSelectedTab.iIndex === 10) {
                this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.social-links", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, false, false);
            } else if (objSelectedTab.iIndex > 10 && objSelectedTab.iIndex <= this.get("_iLastSkillTabIndex")) {
                this.set("_objTitleBarService.objActiveSkillTab", objSelectedTab);
                if (this.get("_bHasSkills")) {
                    this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.skills", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")],
                        "skills": [objSelectedTab.sCode]
                    }, null, false, false);
                } else {
                    this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.skills", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")]
                    }, null, false, false);
                }
            } else if (objSelectedTab.iIndex > this.get("_iLastSkillTabIndex") && objSelectedTab.iIndex <= this.get("_iLastQualificationTabIndex")) {
                this.set("_objTitleBarService.objActiveQualificationTab", objSelectedTab);
                if (this.get("_bHasQualifications")) {
                    this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.qualifications", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")],
                        "qualifications": [objSelectedTab.sCode]
                    }, null, false, false);
                } else {
                    this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.qualifications", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")]
                    }, null, false, false);
                }
            } else if (objSelectedTab.iIndex > this.get("_iLastQualificationTabIndex") && objSelectedTab.iIndex <= this.get("_iLastProfessionalRegistrationTabIndex")) {
                this.set("_objTitleBarService.objActiveProfessionalRegistrationTab", objSelectedTab);
                if (this.get("_bHasProfessionalRegistrations")) {
                    this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.professional-registrations", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")],
                        "professional-registrations": [objSelectedTab.sCode]
                    }, null, false, false);
                } else {
                    this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.professional-registrations", {
                        "agency-candidate-search-view": [this.get("_sCandidateId")]
                    }, null, false, false);
                }
            } else if (objSelectedTab.iIndex === this.get("_iLastProfessionalRegistrationTabIndex") + 1) {
                this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.references", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, false, false);
            } else if (objSelectedTab.iIndex === this.get("_iLastProfessionalRegistrationTabIndex") + 2) {
                this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.tags", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, false, false);
            } else if (objSelectedTab.iIndex === this.get("_iLastProfessionalRegistrationTabIndex") + 3) {
                this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.calendar", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, false, false);
            } else if (objSelectedTab.iIndex === this.get("_iLastProfessionalRegistrationTabIndex") + 4) {
                this.get("_objNavigationService").navigateWithinContext("agency-candidate-search-view.notifications-preferences", {
                    "agency-candidate-search-view": [this.get("_sCandidateId")]
                }, null, false, false);
            }
        },

        onNoticationEmailClick: function onNoticationEmailClick() {
            var _this5 = this;

            this.clearForm();

            this.set("_bShowSendNotificationPopup", true);
            this.set("_bDisplayPreviewSend", false);

            if (a24Core.isEmpty(this.get("_arrNotificationTypeDropdown"))) {
                this.set("_bSendNotificationPopupLoading", true);
                var objExtraParams = {
                    enabled: {
                        mValue: true
                    }
                };

                this.get("_objRestAgencyNotificationTypes").getNotificationTypes(this, "_objGetNotificationTypesServiceCall", function (arrData) {
                    if (!a24Core.isEmpty(arrData)) {

                        var arrNotificationTypeDropdown = [];
                        for (var l = 0; l < arrData.length; l++) {
                            arrNotificationTypeDropdown.push({
                                sTitle: arrData[l].name,
                                sValue: arrData[l].metadata.type
                            });
                        }

                        _this5.set("_arrNotificationTypeDropdown", arrNotificationTypeDropdown);
                    }

                    _this5.set("_bSendNotificationPopupLoading", false);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    var sServerError = _this5.get("_objStringsService").getString("serverError");
                    _this5.get("_objSnackbarService").showSnackbarMessage(sServerError);

                    _this5.set("_bShowSendNotificationPopup", false);
                }), objExtraParams);
            }
        },
        hidePopupAndShowSnackbar: function hidePopupAndShowSnackbar(sMessage) {
            // Hide the popup
            this.set("_bShowResendInvitePopup", false);
            // Show the snackbar message
            this.get("_objSnackbarService").showSnackbarMessage(sMessage);
        },

        onInputValueChanged: function onInputValueChanged(arrPathToInputMixin) {
            var _this6 = this;

            var sLookup = arrPathToInputMixin.join(".");

            if (sLookup === "objChildren.for") {
                this.set("_sForString", this.get(sLookup + ".mValue"));
            } else if (sLookup === "objChildren.in") {
                this.set("_sInString", this.get(sLookup + ".mValue"));
            } else if (sLookup === "objChildren.shiftPattern") {
                this.set("_sShiftPatternString", this.get(sLookup + ".mValue"));
            } else if (sLookup === "objChildren.rates") {
                this.set("_sRatesString", this.get(sLookup + ".mValue"));
            } else if (sLookup === "objChildren.replyWith") {
                this.set("_sReplyWithString", this.get(sLookup + ".mValue"));
            }

            if (sLookup === "objChildren._objNotificationType" && !a24Core.isEmpty(this.get("objChildren._objNotificationType.mValue"))) {
                this.set("_sNotificationId", this.get("objChildren._objNotificationType.mValue.sValue"));
                this.set("_bShiftNotification", false);
                if (this.get("_sNotificationId") === "possible_work_notification") {
                    this.set("_bShiftNotification", true);
                }
                this.set("_bShowNotificationTypes", true);
                if (a24Core.isEmpty(this.get("_arrNotificationCache." + this.get("_sNotificationId")))) {

                    var arrAgencyGroups = this.get("_objSettingsRestService.objSettings.agency_groups");

                    var arrCandidateTypes = [];
                    if (!a24Core.isEmpty(arrAgencyGroups)) {
                        for (var i = 0; i < arrAgencyGroups.length; i++) {
                            if (!a24Core.isEmpty(arrAgencyGroups[i].candidate_type)) {
                                arrCandidateTypes.push(arrAgencyGroups[i].candidate_type);
                            }
                        }
                    }

                    var objExtraParams = {
                        position_types: {
                            mValue: arrCandidateTypes
                        },
                        context_notification_type: {
                            mValue: this.get("_sNotificationId")
                        },
                        status: {
                            mValue: "enabled"
                        }
                    };

                    this.set("_bNotificationTypeLoading", true);
                    this.set("_bDisplayPreviewSend", false);
                    this.get("_objRestAgencyNotificationTypeTemplates").getNotificationTypeTemplates(this, "_objGetNotificationTypesServiceCall", function (arrData) {
                        if (!a24Core.isEmpty(arrData)) {
                            _this6.set("_arrNotificationTypeComponents", _this6._setupTagComponents(arrData));
                            _this6.set("_bDisplayPreviewSend", true);
                        } else {
                            _this6.set("_arrNotificationTypeComponents", []);
                        }
                        _this6.set("_arrNotificationCache." + _this6.get("_sNotificationId"), _this6.get("_arrNotificationTypeComponents"));
                        _this6.set("_bNotificationTypeLoading", false);
                        _this6.set("_bShowNotificationTypes", true);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        var sServerError = _this6.get("_objStringsService").getString("serverError");
                        _this6.get("_objSnackbarService").showSnackbarMessage(sServerError);
                        _this6.set("_bNotificationTypeLoading", false);
                        _this6.set("_bDisplayPreviewSend", false);
                        _this6.set("_bShowNotificationTypes", false);
                    }), objExtraParams);
                } else {
                    this.set("_arrNotificationTypeComponents", this.get("_arrNotificationCache." + this.get("_sNotificationId")));
                    this.set("_bDisplayPreviewSend", true);
                    this.set("_bNotificationTypeLoading", false);
                }
            } else if (a24Core.isEmpty(this.get("objChildren._objNotificationType.mValue"))) {
                this.set("_bDisplayPreviewSend", false);
                this.set("_bShowNotificationTypes", false);
            }
        },
        setPriorityGroupNames: function setPriorityGroupNames(arrGroupings) {
            // replace priority 1 with Primary Delivery
            // replace 2 with 1st Fallback
            // replace 3 with 2nd Fallback
            // replace 4 with 3rd Fallback
            arrGroupings[0].sTitle = this.get("_objStringsCandidateProfile").getString("primaryDelivery");
            if (arrGroupings.length > 1) {
                arrGroupings[1].sTitle = this.get("_objStringsCandidateProfile").getString("firstFallback");
            }
            if (arrGroupings.length > 2) {
                arrGroupings[2].sTitle = this.get("_objStringsCandidateProfile").getString("secondFallback");
            }
            if (arrGroupings.length > 3) {
                arrGroupings[3].sTitle = this.get("_objStringsCandidateProfile").getString("thirdFallback");
            }
            if (arrGroupings.length > 4) {
                for (var i = 4; i < arrGroupings.length; i++) {
                    arrGroupings[i].sTitle = i + this.get("_objStringsCandidateProfile").getString("th") + " " + this.get("_objStringsCandidateProfile").getString("fallback");
                }
            }
            return arrGroupings;
        },
        _setupTagComponents: function _setupTagComponents(arrNotificationTypeTemplates) {
            var _this7 = this;

            var objThis = this;
            var funcGroupFilterExecute = function funcGroupFilterExecute(objItem) {
                return function (objComponent) {
                    return objItem.priority === objComponent.objData.mValue.priority;
                };
            };

            var arrGroupings = arrNotificationTypeTemplates.uniqBy("priority").sortBy("priority").map(function (objItem) {
                return {
                    sTitle: objItem.priority,
                    funcGroupFilter: funcGroupFilterExecute(objItem)
                };
            });

            arrGroupings = this.setPriorityGroupNames(arrGroupings);

            var arrComponents = arrNotificationTypeTemplates.map(function (objEntry) {
                return {
                    sComponent: "tag-component-shrink",
                    objData: {
                        sTitle: _this7.get("_objStaffshiftCoreEnums").getFrontendValue("mediumType", objEntry.type),
                        mValue: objEntry,
                        sColor: "a24-primary-color-hue-3-force white-text",
                        sActionIcon: "remove_red_eye",
                        sActionIconColor: "white-text"
                    },
                    objActions: {
                        onTagClicked: function onTagClicked(sTitle, objItem) {

                            if (objThis.get("_sNotificationId") === "possible_work_notification") {
                                objThis.clearAllErrorMessages();

                                if (!objThis.doFullValidation()) {
                                    var objItemData = Ember.copy(objItem.data, true);
                                    var objData = objThis.getPlainFormValueObject();

                                    var sShiftDetails = objThis.get("_objStringsCandidateProfile").getString("for") + " " + objData.for + " " + (objThis.get("_objStringsService").getString("in").toLowerCase() + " " + objData.in + ". " + objData.shiftPattern);

                                    if (!a24Core.isEmpty(objData.rates)) {
                                        sShiftDetails += ". " + objData.rates;
                                    }

                                    if (objItem.type === objThis.get("_objStaffshiftCoreEnums.mediumType.EMAIL.backend")) {
                                        // replace shift_details and reply_with
                                        var sHtml = objItemData.html;
                                        sHtml = sHtml.replace("{{shift_details}}", sShiftDetails);
                                        sHtml = sHtml.replace("{{reply_with}}", objData.replyWith);
                                        objItemData.html = sHtml;
                                    } else {
                                        // replace shift_details and reply_with
                                        var _sHtml = objItemData.text;
                                        _sHtml = _sHtml.replace("{{shift_details}}", sShiftDetails);
                                        _sHtml = _sHtml.replace("{{reply_with}}", objData.replyWith);
                                        objItemData.text = _sHtml;
                                    }

                                    // show medium preview
                                    objThis.activityNotificationFromMedium(objItem.type, objItemData);

                                    objThis.set("_bShowNotificationPreviewPopup", true);
                                }
                            } else {
                                // show medium preview
                                objThis.activityNotificationFromMedium(objItem.type, objItem.data);

                                objThis.set("_bShowNotificationPreviewPopup", true);
                            }
                        }
                    }
                };
            });

            return {
                arrComponents: arrComponents,
                arrGroupings: arrGroupings,
                sGroupTitleStyle: "font-md a24-grey-text-darken-1"
            };
        },
        activityNotificationFromMedium: function activityNotificationFromMedium(sType, objData) {
            if (sType === this.get("_objStaffshiftCoreEnums.mediumType.EMAIL.backend")) {
                this.set("_bIsEmail", true);
                this.set("_objNotificationPreview", {
                    sSubject: objData.subject,
                    objFrom: objData.send_by,
                    objTo: null,
                    arrCC: null,
                    arrBCC: null,
                    objReplyTo: objData.reply_to,
                    sHTML: objData.html
                });
            } else {
                this.set("_bIsEmail", false);
                this.set("_objNotificationPreview", {
                    collected_tokens: [],
                    sender_phone_number_formatted: null,
                    recipient_mobile_number_formatted: null,
                    medium: sType,
                    data: {
                        body: objData.text
                    },
                    created_at: null,
                    status: null
                });
            }
        },
        sendNotification: function sendNotification() {
            var _this8 = this;

            var sCandidateId = this.get("_sCandidateId");
            var sNotificationId = this.get("_sNotificationId");

            var objData = {
                type: sNotificationId,
                data: {}
            };

            if (this.get("_sNotificationId") === "possible_work_notification") {
                if (!this.doFullValidation()) {
                    // call send notification service
                    this.set("_bSendNotificationPopupLoading", true);

                    var objInputData = this.getPlainFormValueObject();

                    var sShiftDetails = this.get("_objStringsCandidateProfile").getString("for") + " " + objInputData.for + " " + (this.get("_objStringsService").getString("in").toLowerCase() + " " + objInputData.in + ". " + objInputData.shiftPattern);

                    if (!a24Core.isEmpty(objInputData.rates)) {
                        sShiftDetails += ". " + objInputData.rates;
                    }

                    objData.data = {
                        shift_details: sShiftDetails,
                        reply_with: objInputData.replyWith
                    };

                    this.get("_objRestAgencyCandidateNotification").sendNotification(this, "_objSendNotificationServiceCall", sCandidateId, objData, function () {
                        _this8.set("_bSendNotificationPopupLoading", false);
                        _this8.set("_bShowSendNotificationPopup", false);
                        _this8.set("_bShowSentMessagePopup", true);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                        var sServerError = "";
                        if (sCode === "404") {
                            sServerError = _this8.get("_objStringsCandidateProfile").getString("noNotificationForType");
                        } else {
                            sServerError = _this8.get("_objStringsService").getString("serverError");
                        }
                        _this8.get("_objSnackbarService").showSnackbarMessage(sServerError);

                        _this8.set("_bSendNotificationPopupLoading", false);
                    }));
                }
            } else {
                this.set("_bSendNotificationPopupLoading", true);
                this.get("_objRestAgencyCandidateNotification").sendNotification(this, "_objSendNotificationServiceCall", sCandidateId, objData, function () {
                    _this8.set("_bSendNotificationPopupLoading", false);
                    _this8.set("_bShowSendNotificationPopup", false);
                    _this8.set("_bShowSentMessagePopup", true);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    var sServerError = "";
                    if (sCode === "404") {
                        sServerError = _this8.get("_objStringsCandidateProfile").getString("noNotificationForType");
                    } else {
                        sServerError = _this8.get("_objStringsService").getString("serverError");
                    }
                    _this8.get("_objSnackbarService").showSnackbarMessage(sServerError);

                    _this8.set("_bSendNotificationPopupLoading", false);
                }));
            }
        },
        onPopupNotificationCancel: function onPopupNotificationCancel() {
            if (this.get("_bSendNotificationPopupLoading")) {
                return;
            }

            this.set("_bShowSendNotificationPopup", false);
        },
        willDestroy: function willDestroy() {
            this._super.apply(this, arguments);
            this.get("_objTitleBarService").setTabItems(null, null);
        },
        actions: {
            onPopupSendNotificationCancel: function onPopupSendNotificationCancel() {
                this.onPopupNotificationCancel();
            },
            onPopupSendNotificationPreviewSend: function onPopupSendNotificationPreviewSend() {
                this.sendNotification();
            },
            onNotificationPreviewPopupClose: function onNotificationPreviewPopupClose() {
                this.set("_bShowNotificationPreviewPopup", false);
            },
            onSentMessagePopupCancel: function onSentMessagePopupCancel() {
                this.set("_bShowSentMessagePopup", false);
            }
        }
    });
});