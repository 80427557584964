define("a24-staffshift-subdomain-agency/components/page-chatter-inbox", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _didRenderChangesMixin, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        _objChatterIframeComService: Ember.inject.service("chatter-iframe-com"),

        //this needs to stay clean to match origin in post message events, so rather copy this and add params where needed
        //and do not alter this property
        _sIFrameUrl: "",
        _bLoading: true,
        _bRightSideLoading: false,
        _funcUnregister: null,

        _momentHelper: null,

        _objSelectedUserDetails: null,

        _sCandidateId: null,
        _objCandidateStatusService: Ember.inject.service("rest-agency-candidate-status"),
        _objCandidateStatusPromise: null,
        _objChatCandidateConversationRestService: Ember.inject.service("rest-agency-chat-candidate-conversations"),
        _objBasicInfoService: Ember.inject.service("rest-agency-candidate-basic-info"),
        _objCandidateTypeRestService: Ember.inject.service("rest-agency-candidate-type"),
        _objSSCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _sActivityContext: null,
        _objActivityRestService: Ember.inject.service("rest-agency-candidate-activity"),
        _objActivityTypeRestService: Ember.inject.service("rest-advanced-search-activity-type"),
        _objRestAgencyCandidateActivityEmailComm: Ember.inject.service("rest-agency-candidate-activity-email-communication-message"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objStaffshiftCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),
        _objRestAgencyCandidateActivityConversation: Ember.inject.service("rest-agency-candidate-activity-conversation"),
        _objRestAgencyCandidateActivityNotification: Ember.inject.service("rest-agency-candidate-activity-notification"),
        _objAdvanceCandidateSearch: Ember.inject.service("rest-agency-advanced-search-candidate"),
        _objAdvanceCandidateSearchPromise: null,
        _objCandidateProfileEnums: Ember.inject.service("a24-enums-candidate-profile"),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            // Note this is for react notifications to work as they require same document domain to push notification
            // messages. This does not effect any api calls as the origin does not change. Also all seems to work except
            // on test cases where it seems to break. Thus the env check
            if (_environment.default.environment !== "test") {
                document.domain = "staffshift.com";
            }

            if ("Notification" in window) {
                if (Notification.permission !== "granted") {
                    Notification.requestPermission();
                }
            }

            this.set("_sActivityContext", this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY").toLowerCase());

            if (a24Core.isEmpty(this.get("_sIFrameUrl"))) {
                this.set("_sIFrameUrl", _environment.default.sChatInboxUrl);
            }

            if (!a24Core.isEmpty(this.get("_sIFrameUrl"))) {
                this.set("_funcUnregister", this.get("_objChatterIframeComService").receiveMessage(function (objMessage) {
                    _this._handleMessages(JSON.parse(objMessage.data));
                }, this.get("_sIFrameUrl")));
            }
        },


        _postSessionData: function _postSessionData() {
            this.get("_objChatterIframeComService").postSessionData(true, this.get("_objStaffshiftCoreStrings").getString("inbox"), {}, this.get("_sIFrameUrl"), this.$("iframe")[0].contentWindow);
        },

        _setCandidateStatus: function _setCandidateStatus(objData, sCandidateId) {
            var _this2 = this;

            objData.data.ember_candidate = {};
            this.get("_objCandidateStatusService").getCandidateStatusSummary(this, "_objCandidateStatusPromise", sCandidateId, function (objResponse) {
                if (!a24Core.isEmpty(objResponse) && !a24Core.isEmpty(objResponse.current_status)) {
                    _this2.set("_objSelectedUserDetails.ember_candidate.current_status", objResponse.current_status);
                } else {
                    _this2.set("_objSelectedUserDetails.ember_candidate.current_status", {});
                }
            }, // handled in rsvp
            a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode) {
                if (sCode === "500" || sCode === "502" || sCode === "503" || sCode === "504") {
                    _this2.set("_objSelectedUserDetails.ember_candidate.current_status", { status: "---" });
                    return;
                }
                //Handle other codes as no status
            }));

            return objData;
        },

        _handleMessages: function _handleMessages(objData) {
            var _this3 = this;

            if (objData.type === "app_ready") {
                this.set("_bLoading", false);
                this._postSessionData();
            } else if (objData.type === "user_attributes") {
                if (!a24Core.isEmpty(objData.data) && !a24Core.isEmpty(objData.data.ss)) {
                    if (!a24Core.isEmpty(objData.data.ss.context) && !a24Core.isEmpty(objData.data.ss.context.resource) && objData.data.ss.context.resource === this.get("_objSSCoreConstants.CONTEXT_CANDIDATE")) {
                        if (objData.data.ss.context.id !== this.get("_sCandidateId")) {
                            this.set("_sCandidateId", null);
                            Ember.run.scheduleOnce("afterRender", function () {
                                _this3.set("_sCandidateId", objData.data.ss.context.id);
                            });
                        }

                        objData = this._setCandidateStatus(objData, objData.data.ss.context.id);
                    } else {
                        this.set("_sCandidateId", null);
                    }
                    this.set("_objSelectedUserDetails", objData.data);
                } else if (!a24Core.isEmpty(objData.data)) {
                    this.set("_bRightSideLoading", true);
                    this.set("_sCandidateId", null);
                    this.set("_objSelectedUserDetails", null);

                    if (!a24Core.isEmpty(objData.data.chatInbox) && !a24Core.isEmpty(objData.data.chatInbox.identity) && a24Core.isNumber(objData.data.chatInbox.identity)) {
                        this.get("_objAdvanceCandidateSearch").getCandidates(this, "_objAdvanceCandidateSearchPromise", {
                            page: 1,
                            items_per_page: 2,
                            query: {
                                and: [{
                                    contact_numbers: {
                                        contains: [{
                                            number: {
                                                equal: objData.data.chatInbox.identity
                                            }
                                        }]
                                    }
                                }]
                            }
                        }, function (objResponse) {
                            if (a24Core.isEmpty(objResponse)) {
                                objResponse = [];
                            }

                            if (objResponse.length === 1) {
                                _this3.set("_sCandidateId", objResponse[0].candidate_id);
                                objData = _this3._setCandidateStatus(objData, objResponse[0].candidate_id);
                            }

                            _this3.set("_objSelectedUserDetails", objData.data);
                            _this3.set("_bRightSideLoading", false);
                        }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () {
                            _this3.set("_objSelectedUserDetails", objData.data);
                            _this3.set("_bRightSideLoading", false);
                        }));
                    } else {
                        this.set("_objSelectedUserDetails", objData.data);
                        this.set("_bRightSideLoading", false);
                    }
                } else {
                    this.set("_sCandidateId", null);
                    this.set("_objSelectedUserDetails", null);
                }
            } else if (objData.type === "desktop_notification") {
                this.get("_objChatterIframeComService").openDesktopNotification(this.get("_sIFrameUrl"), this.$("iframe")[0].contentWindow, objData.data);
            }
        },

        willDestroyElement: function willDestroyElement() {
            if (!a24Core.isEmpty(this.get("_funcUnregister"))) {
                this.get("_funcUnregister")();
            }
            //no need to do logout post message or something like that, chat app takes care of that.
            this._super.apply(this, arguments);
        },


        actions: {
            onGoToConversation: function onGoToConversation(objConversation) {
                var objData = {
                    type: "select-conversation",
                    data: {
                        conversationId: objConversation._id
                    }
                };

                this.get("_objChatterIframeComService").postMessage(JSON.stringify(objData), this.get("_sIFrameUrl"), this.$("iframe")[0].contentWindow);
            }
        }
    });
});