define("a24-staffshift-subdomain-agency/services/a24-strings-staffshift-agency", ["exports", "a24-ember-lookup/services/a24-strings-base"], function (exports, _a24StringsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24StringsBase.default.extend({
        _objStrings: null,

        init: function init() {
            this._super.apply(this, arguments);
            this.set("_objStrings", {
                contract: "Contract",
                complianceContracts: "Compliance Contracts",
                complianceContract: "Compliance Contract",
                clientSearch: "Client Search",
                contractDetails: "Contract Details",

                hoursDifference: "Hours Difference",
                equal: "Equal",
                lessThan: "Less Than",
                moreThan: "More Than",

                timesheetShiftReference: "Timesheet Shift Reference",
                timesheetEndDate: "Timesheet End Date",
                timesheetStartDate: "Timesheet Start Date",
                timesheetIntervalStatus: "Timesheet Interval Status",
                timesheetTotalHoursPay: "Timesheet Total Hours Pay",
                timesheetBreakHours: "Timesheet Break Hours",
                timesheetBillableHours: "Timesheet Billable Hours",

                for: "For",
                late: "Late",
                early: "Early",
                night: "Night",
                city: "City",
                postCode: "Post Code",

                orderReference: "Order Reference",
                listShifts: "List Shifts",
                searchShifts: "Search Shifts",
                viewOrder: "View Order",
                cancelShift: "Cancel Shift",
                selectReason: "Select Reason",
                aboutToCancelShifts: "You are about to cancel the following shifts:",
                cancelShiftsConfirmationPopup: "Are you sure you want to cancel these shifts?",
                failedShiftsPopup: "Failed to cancel the following shifts:",

                cancelingShifts: "Cancelling Shifts...",
                successCancelShifts: "Successfully Cancelled Shifts",

                notValidReasonCancelShift: "Not a valid reason for cancelling a shift",
                shiftStatusPreventCancel: "Current shift status prevents cancellation",
                somethingWentWrong: "Something went wrong. Please try again in a few minutes",
                bulkPossibleShiftWarning: "Please note that this will send an availible shift notification to all the selected candidates",

                assignComplianceContract: "Assign Compliance Contract",
                removeComplianceContract: "Remove Compliance Contract",

                booked: "Booked",
                unavailable: "Unavailable",
                available: "Available",
                unknown: "Unknown",
                availability: "Availability",
                dateFilterTomorrow: "Tomorrow",
                dateFilterNext5Days: "Next 5 days",
                dateFilterSpecificDate: "Specific date",
                dateFilterAllDatesInRange: "All dates in range",
                dateFilterAnyDatesInRange: "Any dates in range",
                andShiftPeriod: "and Shift Period",
                availabilityLastUpdated: "Availability Last Updated",
                positionHeld: "Position Held",
                assignments: "Assignments",
                orderId: "Order ID",
                siteName: "Site Name",
                wardName: "Ward Name",
                payrollNumber: "Payroll Number",
                shiftReferenceNumber: "Shift Reference Number",
                poNumber: "PO Number",
                dateFilled: "Date Filled",
                filledBy: "Filled By",
                filledByUsername: "Filled By Username",
                assignmentStartDate: "Assignment Start Date",
                assignmentEndDate: "Assignment End Date",
                assignmentStatus: "Assignment Status",
                sendOutstandingTimesheetNotif: "Send Outstanding Timesheet Notification",
                organisationName: "Organisation Name",
                assignmentBookedHours: "Assignment Booked Hours",
                contractName: "Contract Name",
                contractStatus: "Contract Status",
                timesheetNumber: "Timesheet Number",
                timesheetCandidate: "Timesheet Candidate",
                timesheetPayrollNumber: "Timesheet Payroll Number",
                timesheetWardName: "Timesheet Ward Name",
                timesheetWardReference: "Timesheet Ward Reference",
                timesheetPONumber: "Timesheet PO Number",
                timesheetJobFamily: "Timesheet Job Family",
                timesheetJobTitle: "Timesheet Job Title",
                timesheetWeekEnding: "Timesheet Week Ending",
                wardChange: "Ward Change",
                jobTitleChange: "Job Title Change",
                timesheetContractName: "Timesheet Contract Name",
                contractChange: "Contract Change",
                timesheetOverallStatus: "Timesheet Overall Status",
                timesheetTotalExpensesPay: "Timesheet Total Expenses Pay",
                timesheetTotalPay: "Timesheet Total Pay",
                timesheetTotalcharge: "Timesheet Total Charge",
                nodeLevel: "Node Level",
                siteType: "Site Type",
                wardType: "Ward Type",
                site: "Site",
                ward: "Ward",
                clientReference: "Client Reference",
                siteReferenceNumber: "Site Reference Number",
                wardReferenceNumber: "Ward Reference Number",

                openTimesheetInNewTab: "Open Timesheet in new tab",
                sendAvailShiftNotif: "Send available shift notification",
                shiftsAvailable: "Shifts available",
                payRate: "Pay Rate",
                replyWithOrder: "Reply with: Order",
                possibleCandidatesForOrder: "Possible Candidates for Order #",
                possibleShiftsNotificationShiftError: "One or more of the shifts are not valid. This could simply mean that the shift has already been filled or cancelled. Please refresh the page and try again.",

                new: "New",
                open: "Open",
                accepted: "Accepted",
                closed: "Closed",
                cancelled: "Cancelled",
                reopened: "Reopened",
                authorised: "Authorised",
                confirmed: "Confirmed",
                rejected: "Rejected",
                approved: "Approved",

                shiftPattern: "Shift Pattern",
                rates: "Rates",
                replyWith: "Reply With",
                nonShiftNotification: "Non Shift Specific Notification",
                possibleWorkNotification: "Possible Work - Notification",
                emptyStringSpacer: "_____",

                //===== TOKEN STRINGS START =====
                next5Days: "Next 5 days({0} - {1})",
                allDatesRange: "All dates in range({0} - {1})",
                anyDatesRange: "Any dates in range({0} - {1})",
                availabilityTag: "Availability is {0} for {1} with Shift Period in {2}",
                availabilityTagNoTitle: "Is {0} for {1} with Shift Period in {2}",
                timesheetBreakHoursTag: "Has Timesheet Break Hours is {0}",
                timesheetBreakHoursTagNoTitle: "Timesheet Break Hours is {0}",
                hoursDifferenceTag: "Hours Difference {0}",
                hoursDifferenceTagNoTitle: "Hours Difference {0}",

                outstandingTimesheetPreview: "According to our records we had a shift booked for you on {0} with details: Order Ref: {1}; Client: {2}; Staffshift Timesheet Number: #{3}. Please forward us the signed timesheet to wages@a24group.com or reply to this message if you have not worked the shift with the above Staffshift Timesheet Number: #{4} not worked. {5}"
                //===== TOKEN STRINGS END =====

                //===== SAFE STRINGS START =====

                //===== SAFE STRINGS END =====
            });
        }
    });
});