define("a24-staffshift-subdomain-agency/components/page-agency-compliance-rules", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _didRenderChangesMixin, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        objQueryParams: null,
        _objUserSession: Ember.inject.service("user-session"),
        _sUrlBase: null,
        _sSavedSearchRef: null,
        _objRestCandidateType: Ember.inject.service("rest-agency-candidate-type"),
        _objSavedSearchService: Ember.inject.service("rest-agency-saved-searches"),
        init: function init() {
            this._super.apply(this, arguments);
            this.set("_sUrlBase", _environment.default.sRestApiBaseUrl + "/v1/agency/" + this.get("_objUserSession").getUserContextReference().mId + "/compliance-rule");

            this.set("_sSavedSearchRef", "agency-compliance-rule");
        }
    });
});