define("a24-staffshift-subdomain-agency/controllers/index/agency-candidate-search", ["exports", "a24-staffshift-subdomain-agency/mixins/query-param-object-builder"], function (exports, _queryParamObjectBuilder) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, {
        queryParams: ["first_name", "last_name", "full_name", "gender", "candidate_type_id", "city", "province", "post_code", "username", "responsible_consultant", "current_status__status", "current_status__date", "current_status__dateTo", "current_status__dateFrom", "linked_to_agency_at", "linked_to_agency_atTo", "linked_to_agency_atFrom", "emails___email", "contact_numbers___number", "languages___name", "employment_reference_number", "availability_last_updated_at", "availability_last_updated_atFrom", "availability_last_updated_atTo", "availability___status", "last_shift_worked_at", "last_shift_worked_atTo", "last_shift_worked_atFrom", "next_shift_at", "next_shift_atTo", "next_shift_atFrom", "employment_position_held___job_family_id", "physical_address_geo_location", "last_login_at", "last_login_atTo", "last_login_atFrom", "activities___activity_type", "tags_links___tag_id", "skill_details___skill_id", "qualification_details___qualification_id", "registration_body_details___registration_body_id", "registration_body_details___registration_body_entry_id", "keywords___name", "chat_token_last_issued_at", "chat_token_last_issued_atFrom", "chat_token_last_issued_atTo", "notification_dnc", "medium_dnc___type", "transport_type", "page", "sortBy", "vis_cols",

        //Non datagrid query params
        "pos_shift_order_id"],
        first_name: "",
        last_name: "",
        full_name: "",
        gender: "",
        candidate_type_id: "",
        city: "",
        province: "",
        post_code: "",
        username: "",
        responsible_consultant: "",
        current_status__status: "Live|Work Live|Work Live Framework",
        current_status__date: "",
        current_status__dateTo: "",
        current_status__dateFrom: "",
        linked_to_agency_at: "",
        linked_to_agency_atTo: "",
        linked_to_agency_atFrom: "",
        emails___email: "",
        contact_numbers___number: "",
        languages___name: "",
        employment_reference_number: "",
        availability_last_updated_at: "",
        availability_last_updated_atFrom: "",
        availability_last_updated_atTo: "",
        availability___status: "",
        last_shift_worked_at: "",
        last_shift_worked_atTo: "",
        last_shift_worked_atFrom: "",
        next_shift_at: "",
        next_shift_atTo: "",
        next_shift_atFrom: "",
        employment_position_held___job_family_id: "",
        physical_address_geo_location: "",
        last_login_at: "",
        last_login_atTo: "",
        last_login_atFrom: "",
        activities___activity_type: "",
        tags_links___tag_id: "",
        skill_details___skill_id: "",
        qualification_details___qualification_id: "",
        registration_body_details___registration_body_id: "",
        registration_body_details___registration_body_entry_id: "",
        keywords___name: "",
        chat_token_last_issued_at: "",
        chat_token_last_issued_atFrom: "",
        chat_token_last_issued_atTo: "",
        notification_dnc: "",
        medium_dnc___type: "",
        transport_type: "",
        page: "1",
        sortBy: "full_name::asc",
        vis_cols: "1,5,6,9,10,11",

        //Non datagrid query params
        pos_shift_order_id: ""
    });
});