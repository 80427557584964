define("a24-staffshift-subdomain-agency/services/rest-agency-candidate-activity-email-communication-message", ["exports", "a24-ember-candidate-profile/services/rest-agency-candidate-activity-email-communication-message"], function (exports, _restAgencyCandidateActivityEmailCommunicationMessage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _restAgencyCandidateActivityEmailCommunicationMessage.default;
    }
  });
});