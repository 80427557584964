define("a24-staffshift-subdomain-agency/services/card-datagrid-custom-cell-availability", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        iCalculationDayCount: 4,
        _objUserSession: Ember.inject.service("user-session"),
        _objAgencyEnumService: Ember.inject.service("a24-enums-staffshift-agency"),
        sDayNameFormat: "ddd",
        sDayMonthFormat: "DD MMM",
        getAvailDatesToShow: function getAvailDatesToShow(objHeaderItem) {
            var _this = this;

            var mDateFilterValue = objHeaderItem.mFilterValue;

            // Check if the filter value is empty
            if (!a24Core.isEmpty(mDateFilterValue)) {
                // Filter is not empty

                // Create list of lists
                var arrTopLevelRangeList = [];

                // Loop over the filter list
                for (var i = 0; i < mDateFilterValue.length; i++) {
                    // Get the Range item
                    var objRangeItem = mDateFilterValue[i];

                    // Check for the arSec property
                    if (a24Core.isEmpty(objRangeItem.arSec)) {
                        // If the range item has arSec, we treat the arExt[0] as the true range
                        objRangeItem = objRangeItem.arExt[0];
                    }

                    //Get the start date off of the filter
                    var mFilterStart = objRangeItem.arSec.find(function (item) {
                        return item.sProp === "range_start";
                    }).mValue;
                    var mFilterEnd = objRangeItem.arSec.find(function (item) {
                        return item.sProp === "range_end";
                    }).mValue;

                    // Create dates
                    var objDateStart = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    var objDateEnd = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                    // Set dates from items
                    objDateStart.setDateFromRest(mFilterStart, true);
                    objDateEnd.setDateFromRest(mFilterEnd, true);

                    // Create an array of string day items to return
                    var arrDays = [];

                    // Add required days to array
                    for (var j = 0; j < this.get("iCalculationDayCount"); j++) {
                        if (objDateStart.difference(objDateEnd, a24Constants.MOMENT_TIME_DAYS) > 0) {
                            /**
                             * The days are reversed.
                             * This will happen when the Start date is after the End date.
                             * Imagine the scenario of 2001-01-05 -> 2001-01-06
                             * The itterations on the loop will be
                             * 
                             * Start         End
                             * 2001-01-05 -> 2001-01-06 // Skipped this if
                             * 2001-01-06 -> 2001-01-06 // Skipped this if
                             * 2001-01-07 -> 2001-01-06 // Enter this if
                             * 
                             * Once the incrementing date passes the end date, we break
                             * to avoid adding invalid dates to the list.
                             * 
                             * Note: Secondly this will cater for dates that start of reversed
                             * as they won't have any dates in range that can be added
                             */
                            break;
                        }

                        // Get the string formatted date and add it to the array
                        var sDateFormated = objDateStart.getDateStringForRest(true);
                        arrDays.push(sDateFormated);

                        // Add one day to the date
                        objDateStart.addTimeToDate(a24Constants.MOMENT_TIME_DAYS, 1);
                    }

                    arrTopLevelRangeList.push(arrDays);
                }

                // Flatten the array, uniquify, then sort, then cut the first iShowAmountOfDays
                var arrSortedUnique = [];
                for (var _i = 0; _i < arrTopLevelRangeList.length; _i++) {
                    var arrInnerItems = arrTopLevelRangeList[_i];
                    for (var _j = 0; _j < arrInnerItems.length; _j++) {
                        if (arrSortedUnique.indexOf(arrInnerItems[_j]) === -1) {
                            arrSortedUnique.push(arrInnerItems[_j]);
                        }
                    }
                }

                arrSortedUnique = arrSortedUnique.sort(function (objA, objB) {
                    var objDateA = a24DateManager.createDate(_this.get("_objUserSession.objContextDetails.sTimezone"));
                    var objDateB = a24DateManager.createDate(_this.get("_objUserSession.objContextDetails.sTimezone"));

                    // Set dates from items
                    objDateA.setDateFromRest(objA, true);
                    objDateB.setDateFromRest(objB, true);

                    return objDateA.difference(objDateB, a24Constants.MOMENT_TIME_DAYS);
                }).slice(0, this.get("iCalculationDayCount"));

                return arrSortedUnique;
            } else {
                // Filter is empty, build up (today + iCalculationDayCount) days

                // Create a new date object
                var objDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                // Create an array of string day items to return
                var _arrDays = [];

                // Add required days to array
                for (var _i2 = 0; _i2 < this.get("iCalculationDayCount"); _i2++) {

                    // Get the string formatted date and add it to the array
                    var _sDateFormated = objDate.getDateStringForRest(true);
                    _arrDays.push(_sDateFormated);

                    // Add one day to the date
                    objDate.addTimeToDate(a24Constants.MOMENT_TIME_DAYS, 1);
                }

                return _arrDays;
            }
        },
        getAvailDetails: function getAvailDetails(arrDates, objRowData) {
            var arrAvailDetails = [];

            var arrAvailability = objRowData.availability;

            // Loop over the list of given dates
            for (var i = 0; i < arrDates.length; i++) {

                // Create a new date instance
                var objDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                // Set date from array item
                objDate.setDateFromRest(arrDates[i], true);
                objDate.setTimeToStartOfDay(); // Reset time

                // Create default availability
                var objAvail = {};
                objAvail[this.get("_objAgencyEnumService.shiftOptions.EARLY.backend")] = this.get("_objAgencyEnumService.availabilityOptions.UNKNOWN.backend");
                objAvail[this.get("_objAgencyEnumService.shiftOptions.LATE.backend")] = this.get("_objAgencyEnumService.availabilityOptions.UNKNOWN.backend");
                objAvail[this.get("_objAgencyEnumService.shiftOptions.NIGHT.backend")] = this.get("_objAgencyEnumService.availabilityOptions.UNKNOWN.backend");

                var iMatches = 0;

                for (var j = 0; j < arrAvailability.length; j++) {
                    var objAvailability = arrAvailability[j];

                    // Get range start and end for availability item
                    var objRangeStart = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    var objRangeEnd = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                    objRangeStart.setDateFromRest(objAvailability.range_start);
                    objRangeEnd.setDateFromRest(objAvailability.range_end);

                    objRangeStart.setTimeToStartOfDay(); // Reset time
                    objRangeEnd.setTimeToStartOfDay(); // Reset time

                    // Check if the date item is within the range
                    var bDateWithinRange = objDate.difference(objRangeStart, a24Constants.MOMENT_TIME_DAYS) >= 0 && objDate.difference(objRangeEnd, a24Constants.MOMENT_TIME_DAYS) <= 0;

                    // If the date is within the range, we need to set period to status
                    if (bDateWithinRange) {

                        var sPeriod = objAvailability.period;
                        var sStatus = objAvailability.status;

                        // Set actual period to actual status
                        objAvail[sPeriod] = sStatus;

                        // Once 3 periods have been matched, we can break to avoid looping unnecessarily
                        iMatches++;
                        if (iMatches === 3) {
                            break;
                        }
                    }
                }

                // Add availability details to final array
                arrAvailDetails.push({
                    sDayOfWeek: momentHelper.stringFormat(arrDates[i], a24Constants.DATE_FORMAT_REST_FORMAT_DATE_ONLY, this.get("sDayNameFormat")),
                    sDayOfMonth: momentHelper.stringFormat(arrDates[i], a24Constants.DATE_FORMAT_REST_FORMAT_DATE_ONLY, this.get("sDayMonthFormat")),
                    objShifts: objAvail
                });
            }

            return arrAvailDetails;
        }
    });
});